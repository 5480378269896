import { Grid } from '@mui/material'
import React from 'react'
import { SpacerTopBottomXXXL } from '../../../design/spacer/spacer'
import { AlignCenter, Container, FlexBoxSA, Sandwich, SubHeadingSDM } from '../../../global/style/global.styles'
import { WhiteContainer } from '../style/home.styles'
import { TestimonialBlock } from './testimonialBlock'
import DP from '../../../assets/icons/couple.png'

export const TestimonialSection = (props) => {
    return (
        <>
            <WhiteContainer>
                <Container>
                    <AlignCenter>
                        <SubHeadingSDM style={{ color: '#D68808' }}>
                            Testimonials
                        </SubHeadingSDM>
                    </AlignCenter>
                    <Sandwich />
                    <Grid container>
                        {
                            props.content
                            &&
                            props.content.map((item, index) => (
                                <Grid item xl={4} lg={4} md={4} sm={12} xs={12} key={index}>
                                    <FlexBoxSA style={{ height: '100%', alignItems: 'normal' }}>
                                        <TestimonialBlock
                                            key={index}
                                            id={item.id}
                                            dp={item.dp === "" ? DP : item.dp}
                                            name={item.name}
                                            line1={item.line1}
                                            line2={item.line2}
                                            line3={item.line3}
                                            line4={item.line4}
                                            line5={item.line5}
                                        />
                                    </FlexBoxSA>
                                </Grid>
                            ))
                        }
                    </Grid>
                </Container>
            </WhiteContainer>
        </>
    )
}
