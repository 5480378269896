import { LoadingButton } from '@mui/lab'
import { Alert, Button, Grid, IconButton, Snackbar } from '@mui/material'
import { Form, Formik } from 'formik'
import React, { useContext, useRef, useState } from 'react'
import { SpacerRightSmall, SpacerTopBottomLarge, SpacerTopBottomMedium, SpacerTopBottomSmall, SpacerTopBottomXL, SpacerTopBottomXXL, SpacerTopBottomXXXL } from '../../../design/spacer/spacer'
import { BodyMNun, Container, FlexBox, FlexBoxSA, FormError, FormInput, FormInputTextarea, Sandwich, Squeezer, SubBodyGLSBLNun, SubBodyGMSBNun, SubHeadingGMM, SubHeadingSDM, SubTitle, SubTitleGLM, SubTitleSPDM } from '../../../global/style/global.styles'
import { ContactFormSchema } from '../../../global/validation/validation'
import SaveIcon from '@mui/icons-material/Save';
import { addDoc, serverTimestamp } from 'firebase/firestore'
import { ApiContext } from '../../../context/api/api.provider'
import emailjs from '@emailjs/browser';
import CloseIcon from '@mui/icons-material/Close';
import { ContactFormContainer } from '../style/contact.styles'

const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    date: ""
};

export const ContactForm = (props) => {

    const [details, setDetails] = useState("");
    const [date, setDate] = useState("");

    const [loading, setLoading] = useState(false);

    const { leadsCollectionRef } = useContext(ApiContext);

    const form = useRef();

    const notifyCXO = (values) => {

        emailjs.sendForm(
            process.env.REACT_APP_EMAILJS_SERVICE_ID,
            process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
            form.current,
            process.env.REACT_APP_EMAILJS_PUBLIC_KEY
        )
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });
    }

    const handleSubmit = async (values) => {

        setLoading(true);

        const data = {
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
            phone: values.phone,
            details: details,
            date: values.date,
            createdAt: serverTimestamp()
        }

        await addDoc(leadsCollectionRef, data);

        setLoading(false);
        setDetails("");
        setDate("");
        handleClick();

    }

    const [open, setOpen] = React.useState(false);

    const handleClick = () => {
        setOpen(true);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    const action = (
        <React.Fragment>
            <Button color="secondary" size="small" onClick={handleClose}>

            </Button>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleClose}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    );

    return (
        <>
            <Formik
                initialValues={initialValues}
                validationSchema={ContactFormSchema}
                onSubmit={(values, { resetForm }) => {
                    handleSubmit(values);
                    notifyCXO(values);
                    resetForm();
                }}
            >
                {(formik) => {
                    const { errors, touched, isValid, dirty } = formik;
                    return (
                        <ContactFormContainer>
                            <Form ref={form}>
                                <FlexBoxSA>
                                    <div>
                                        <SubHeadingSDM style={{ color: '#D68808' }}>
                                            Book an appointment
                                        </SubHeadingSDM>
                                        <SpacerTopBottomXXXL />
                                        <Grid container spacing={2}>
                                            <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                                <BodyMNun>
                                                    First Name *
                                                </BodyMNun>
                                                <SpacerTopBottomSmall />
                                                <FormInput
                                                    name="firstName"
                                                    id="firstName"
                                                />
                                                <FormError
                                                    name="firstName"
                                                    component="span"
                                                />
                                            </Grid>
                                            <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                                <BodyMNun>
                                                    Last Name
                                                </BodyMNun>
                                                <SpacerTopBottomSmall />
                                                <FormInput
                                                    name="lastName"
                                                    id="lastName"
                                                />
                                                <FormError
                                                    name="lastName"
                                                    component="span"
                                                />
                                            </Grid>
                                        </Grid>
                                        <SpacerTopBottomLarge />
                                        <Grid container spacing={2}>
                                            <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                                <BodyMNun>
                                                    Email Address *
                                                </BodyMNun>
                                                <SpacerTopBottomSmall />
                                                <FormInput
                                                    name="email"
                                                    id="email"
                                                />
                                                <FormError
                                                    name="email"
                                                    component="span"
                                                />
                                            </Grid>
                                            <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                                <BodyMNun>
                                                    Phone Number *
                                                </BodyMNun>
                                                <SpacerTopBottomSmall />
                                                <FormInput
                                                    name="phone"
                                                    id="phone"
                                                    type="number"
                                                />
                                                <FormError
                                                    name="phone"
                                                    component="span"
                                                />
                                            </Grid>
                                        </Grid>
                                        <SpacerTopBottomLarge />
                                        <Grid container>
                                            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                                                <BodyMNun>
                                                    Event Details *
                                                </BodyMNun>
                                                <SpacerTopBottomSmall />
                                                <FormInputTextarea
                                                    name="details"
                                                    id="details"
                                                    rows={6}
                                                    value={details}
                                                    onChange={(e) => setDetails(e.target.value)}
                                                />
                                                <FormError
                                                    name="details"
                                                    component="span"
                                                />
                                            </Grid>
                                        </Grid>
                                        <SpacerTopBottomLarge />
                                        <Grid container spacing={2}>
                                            <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                                <FlexBox>
                                                    <BodyMNun>
                                                        Event Date
                                                    </BodyMNun>
                                                    {/* <SpacerRightSmall />
                                                <SubBodyGLSBLNun>(optional)</SubBodyGLSBLNun> */}
                                                </FlexBox>
                                                <SpacerTopBottomSmall />
                                                <FormInput
                                                    name="date"
                                                    id="date"
                                                    type="date"
                                                />
                                                <FormError
                                                    name="date"
                                                    component="span"
                                                />
                                            </Grid>
                                        </Grid>
                                        <SpacerTopBottomXL />
                                        <FlexBox>
                                            {
                                                loading
                                                    ?
                                                    <LoadingButton
                                                        loading
                                                        loadingPosition="start"
                                                        startIcon={<SaveIcon />}
                                                        variant="outlined"
                                                        sx={{
                                                            textTransform: 'none'
                                                        }}
                                                        size='large'
                                                        fullWidth
                                                    >
                                                        <SubTitleGLM>
                                                            Saving
                                                        </SubTitleGLM>
                                                    </LoadingButton>
                                                    :
                                                    <Button
                                                        variant='outlined'
                                                        sx={{
                                                            textTransform: 'none',
                                                            border: '1px solid #CAAF83',
                                                            color: '#CAAF83',
                                                            ':hover': {
                                                                bgcolor: '#FFF0DB',
                                                                border: '1px solid #CAAF83',
                                                            }
                                                        }}
                                                        size='large'
                                                        fullWidth
                                                        type='submit'>
                                                        <SubTitleSPDM style={{ color: '#D68808' }}>
                                                            Submit
                                                        </SubTitleSPDM>
                                                    </Button>
                                            }
                                        </FlexBox>
                                    </div>
                                </FlexBoxSA>
                            </Form>
                        </ContactFormContainer>
                    );
                }}
            </Formik>
            <Snackbar
                open={open}
                autoHideDuration={6000}
                onClose={handleClose}
                action={action}>
                <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                    Thank you!, One of our associates will get back to you shortly.
                </Alert>
            </Snackbar>
        </>
    )
}
