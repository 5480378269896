import React from 'react'
import { SpacerLeftRightMedium, SpacerTopBottomLarge, SpacerTopBottomMedium, SpacerTopBottomSmall } from '../../../design/spacer/spacer'
import { BodyMNun, FlexBox, FlexBoxSA, SocialIcon, SocialIconHolder, SubTitleGHB, SubTitleGHSB, SubTitleMZilla, SubTitleSBZilla } from '../../../global/style/global.styles'
import { ContactInfoContainer, ContactInfoWrapper, ContactSocialIcon, ContactSocialIconHolder } from '../style/contact.styles'
import facebookIcon from '../../../assets/images/facebook.png'
import instagramIcon from '../../../assets/images/instagram.png'
import emailIcon from '../../../assets/images/email.png'
import { Tooltip } from '@mui/material'

export const ContactInfo = (props) => {


    return (
        <>
            <ContactInfoWrapper>
                <ContactInfoContainer>
                    <SubTitleSBZilla>
                        The Oyster Studios
                    </SubTitleSBZilla>
                    <SpacerTopBottomMedium />
                    <div style={{ width: props.width > 650 ? '60%' : '100%' }}>
                        <BodyMNun style={{ letterSpacing: '0.04em', lineHeight: '24px' }}>
                            {props.contact && props.contact.address}
                        </BodyMNun>
                        <SpacerTopBottomSmall />
                        <BodyMNun style={{ letterSpacing: '0.04em', lineHeight: '24px' }}>
                            {props.contact && props.contact.phone}
                        </BodyMNun>
                        <BodyMNun style={{ letterSpacing: '0.04em', lineHeight: '24px' }}>
                            {props.contact && props.contact.email}
                        </BodyMNun>
                    </div>
                    <SpacerTopBottomMedium />
                    <FlexBox>
                        <a href={`mailto:${props.contact && props.contact.email}`} target="_blank" style={{ textDecoration: 'none' }}>
                            <Tooltip title="Send email">
                                <ContactSocialIconHolder>
                                    <ContactSocialIcon src={emailIcon} />
                                </ContactSocialIconHolder>
                            </Tooltip>
                        </a>
                        <SpacerLeftRightMedium />
                        <a href={props.contact && props.contact.facebook} target="_blank" style={{ textDecoration: 'none' }}>
                            <Tooltip title={props.contact && props.contact.facebook.slice(12)}>
                                <ContactSocialIconHolder>
                                    <ContactSocialIcon src={facebookIcon} />
                                </ContactSocialIconHolder>
                            </Tooltip>
                        </a>
                        <SpacerLeftRightMedium />
                        <a href={props.contact && props.contact.instagram} target="_blank" style={{ textDecoration: 'none' }}>
                            <Tooltip title={props.contact && props.contact.instagram.slice(12)}>
                                <ContactSocialIconHolder>
                                    <ContactSocialIcon src={instagramIcon} />
                                </ContactSocialIconHolder>
                            </Tooltip>
                        </a>
                    </FlexBox>
                    <SpacerTopBottomMedium />
                </ContactInfoContainer>
            </ContactInfoWrapper>
        </>
    )
}
