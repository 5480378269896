import styled from 'styled-components'

// ----------------------------------------CONTAINERS----------------------------------------//

export const AboutHeroWrapper = styled.div`
    height: 100vh;
    position: relative;
    overflow: hidden;
`;

export const AboutHeroImg = styled.img`
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    z-index: -1;
    height: 64%;

    @media only screen and (max-width: 850px) {
        height: 50%;
    }
    @media only screen and (max-width: 450px) {
        height: 40%;
    }
`;

export const AboutLayer = styled.div`
    background-color: rgba(255, 255, 255, 0.64);
    height: 100vh;
`;

export const AboutUsText = styled.div`
    font-size: ${(props) => props.theme.fontSizes.title};
    color: ${(props) => props.theme.colors.text.black};
    font-weight: ${(props) => props.theme.fontWeights.regular};
    letter-spacing: 0.4px;
    line-height: 40px;
    text-align: justify;

    @media only screen and (max-width: 450px) {
        font-size: ${(props) => props.theme.fontSizes.subTitle};
        letter-spacing: 0.2px;
        line-height: 32px;
        text-align: left;
    }
    @media only screen and (max-width: 350px) {
        font-size: ${(props) => props.theme.fontSizes.body};
        line-height: 28px;
    }
`;

export const AboutImgHolder = styled.div`
    
`;

export const AboutImg = styled.img`
    width: 100%;
    height: 100%;
`;