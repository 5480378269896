import React, { useState } from 'react'
import { Sandwich, Squeezer } from '../../../global/style/global.styles'
import { GalleryContainer, Image, ImagesContainer } from '../../home/style/home.styles'
import { AlbumGalleryContainer } from '../style/gallery.styles'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { FilmsComp } from './filmsComp';
import { ModalContext } from '../../../context/interface/modal.provider';
import { useContext } from 'react';

export const AlbumGallery = (props) => {

    const {
        imgModal,
        setImgModal,
        tempUrl,
        setTempUrl
    } = useContext(ModalContext);

    const showImage = (url) => {
        setTempUrl(url);
        setImgModal(true);
    }

    return (
        <>
            <AlbumGalleryContainer>
                {
                    props.eventDisplay === "Films"
                        ?
                        <FilmsComp
                            data={props.videoData}
                        />
                        :
                        <Sandwich>
                            <Squeezer>
                                <div className={imgModal ? "modal open" : "modal"}>
                                    <img src={tempUrl} />
                                    <CloseRoundedIcon onClick={() => setImgModal(false)} />
                                </div>
                                <GalleryContainer>
                                    {
                                        props.data
                                        &&
                                        props.data.filter((x) => x.event === props.eventDisplay).map((item, index) => (
                                            <ImagesContainer key={index} onClick={() => showImage(item.url)}>
                                                <Image src={item.url} />
                                            </ImagesContainer>
                                        ))
                                    }
                                </GalleryContainer>
                            </Squeezer>
                        </Sandwich>
                }
            </AlbumGalleryContainer>
        </>
    )
}
