import React, { useState, useEffect, useContext } from 'react';
import '../style/global.css'
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { SpacerTopBottomLarge, SpacerTopBottomMedium, SpacerTopBottomXL } from '../../design/spacer/spacer'
import { FlexBoxSA, FlexBoxSB, FlexColumnSB, HamburgerContainer, MenuContainer, NavLogo, NavLogoHolder, Sandwich, SocialIcon, SocialIconHolder, Squeezer, TitleGHSBNun } from '../style/global.styles';
import LOGO from '../../assets/images/logo.png'
import facebookIcon from '../../assets/images/facebook.png'
import instagramIcon from '../../assets/images/instagram.png'
import { Tooltip } from '@mui/material';

export default function RightDrawer(props) {

    const navigate = useNavigate();

    const [state, setState] = useState({
        right: false,
    });

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };

    const list = (anchor) => (
        <Box
            sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <List>
                <MenuContainer>
                    <FlexColumnSB style={{ height: '100%' }}>
                        <FlexColumnSB style={{ height: '64%' }}>
                            <Link to="/"
                                style={{
                                    textDecoration: 'none'
                                }}>
                                <TitleGHSBNun>HOME</TitleGHSBNun>
                            </Link>
                            <Link to="/about"
                                style={{
                                    textDecoration: 'none'
                                }}>
                                <TitleGHSBNun>ABOUT</TitleGHSBNun>
                            </Link>
                            <Link to="/gallery/albums"
                                style={{
                                    textDecoration: 'none'
                                }}>
                                <TitleGHSBNun>GALLERY</TitleGHSBNun>
                            </Link>
                            <Link to="/contact"
                                style={{
                                    textDecoration: 'none'
                                }}>
                                <TitleGHSBNun>CONTACT</TitleGHSBNun>
                            </Link>
                        </FlexColumnSB>
                        <FlexBoxSA>
                            <a href={props.contact && props.contact.facebook} target="_blank" style={{ textDecoration: 'none' }}>
                                <SocialIconHolder>
                                    <SocialIcon src={facebookIcon} />
                                </SocialIconHolder>
                            </a>
                            <a href={props.contact && props.contact.instagram} target="_blank" style={{ textDecoration: 'none' }}>
                                <SocialIconHolder>
                                    <SocialIcon src={instagramIcon} />
                                </SocialIconHolder>
                            </a>
                        </FlexBoxSA>
                    </FlexColumnSB>
                </MenuContainer>
            </List>
        </Box>
    );

    return (
        <div>
            {['right'].map((anchor, index) => (
                <React.Fragment key={index}>
                    <Squeezer>
                        <FlexBoxSB>
                            <NavLogoHolder onClick={() => navigate("/")}>
                                <NavLogo src={LOGO} />
                            </NavLogoHolder>
                            <HamburgerContainer onClick={toggleDrawer(anchor, true)}>
                                <MenuRoundedIcon
                                    sx={{
                                        color: 'white'
                                    }}
                                />
                            </HamburgerContainer>
                        </FlexBoxSB>
                    </Squeezer>
                    <Drawer
                        anchor={anchor}
                        open={state[anchor]}
                        onClose={toggleDrawer(anchor, false)}
                    >
                        {list(anchor)}
                    </Drawer>
                </React.Fragment>
            ))}
        </div>
    );
}