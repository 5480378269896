import { Grid } from '@mui/material'
import React from 'react'
import { SpacerLeftRightMedium } from '../../../design/spacer/spacer'
import LongMenu from '../../../global/components/longMenu'
import { BodyGH, FlexBox, Squeezer, SubTitle, SubTitleMZilla } from '../../../global/style/global.styles'
import { AlbumNavContainer } from '../style/gallery.styles'

export const AlbumNav = (props) => {

    return (
        <>
            <AlbumNavContainer ref={props.albumNavRef}>
                <Squeezer>
                    <Grid container alignItems="center">
                        <Grid item xl={4} lg={3} md={3} sm={6} xs={6}>
                            <SubTitleMZilla>
                                The Oyster Studios
                            </SubTitleMZilla>
                        </Grid>
                        {/* <Grid item xl={8} lg={9} md={9} sm={6} xs={6}>
                            <FlexBox style={{ justifyContent: 'end' }}>
                                {
                                    props.width > 850
                                        ?
                                        props.navData.map((item, index) => (
                                            <>
                                                <BodyGH
                                                    key={index}
                                                    style={
                                                        props.eventDisplay === item
                                                            ?
                                                            { color: '#000000', fontWeight: 500 }
                                                            :
                                                            { color: '#6A6A6A', cursor: 'pointer' }
                                                    }
                                                    onClick={() => props.setEventDisplay(item)}>
                                                    {item}
                                                </BodyGH>
                                                <SpacerLeftRightMedium />
                                            </>
                                        ))
                                        :
                                        <LongMenu
                                            options={props.navData}
                                            selectedOption={props.eventDisplay}
                                        />
                                }
                            </FlexBox>
                        </Grid> */}
                    </Grid>
                </Squeezer>
            </AlbumNavContainer>
        </>
    )
}
