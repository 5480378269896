import styled from 'styled-components'

// ----------------------------------------CONTAINERS----------------------------------------//

export const MapContainer = styled.div`
    width: 100%;
    height: 280px;
`;

export const ContactInfoWrapper = styled.div`

`;

export const ContactInfoContainer = styled.div`

`;

export const ContactSocialIconHolder = styled.div`
    width: 32px;
    height: 32px;
`;

export const ContactSocialIcon = styled.img`
    width: 100%;
    height: 100%;
`;

export const ContactDetailsContainer = styled.div`
    background-color: #FFFFFF;
    padding-top: ${(props) => props.theme.space[6]};
    padding-bottom: ${(props) => props.theme.space[6]};
    padding-left: ${(props) => props.theme.space[20]};
    padding-right: ${(props) => props.theme.space[4]};
    
    @media only screen and (min-width: 1800px) {
        padding-left: ${(props) => props.theme.space[31]};
    }
    @media only screen and (max-width: 1300px) {
        padding-left: ${(props) => props.theme.space[10]};
    }
    @media only screen and (max-width: 1100px) {
        padding-left: ${(props) => props.theme.space[8]};
    }
    @media only screen and (max-width: 850px) {
        padding-left: ${(props) => props.theme.space[10]};
        padding-right: ${(props) => props.theme.space[10]};
    }
    @media only screen and (max-width: 650px) {
        padding-left: ${(props) => props.theme.space[3]};
        padding-right: ${(props) => props.theme.space[2]};
    }
    @media only screen and (max-width: 450px) {
        padding-left: ${(props) => props.theme.space[2]};
        padding-right: ${(props) => props.theme.space[2]};
    }
    @media only screen and (max-width: 370px) {
        padding-left: ${(props) => props.theme.space[1]};
        padding-right: ${(props) => props.theme.space[1]};
    }
`;

export const ContactFormContainer = styled.div`
    background-color: #FFFCF8;
    padding-top: ${(props) => props.theme.space[6]};
    padding-bottom: ${(props) => props.theme.space[6]};
    padding-left: ${(props) => props.theme.space[4]};
    padding-right: ${(props) => props.theme.space[20]};
    border-top-right-radius: 24px;
    border-bottom-right-radius: 24px;
    
    @media only screen and (min-width: 1800px) {
        padding-right: ${(props) => props.theme.space[31]};
    }
    @media only screen and (max-width: 1300px) {
        padding-right: ${(props) => props.theme.space[10]};
    }
    @media only screen and (max-width: 1100px) {
        padding-right: ${(props) => props.theme.space[8]};
    }
    @media only screen and (max-width: 850px) {
        padding-left: ${(props) => props.theme.space[10]};
        padding-right: ${(props) => props.theme.space[10]};
        margin-top: ${(props) => props.theme.space[30]};
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
    @media only screen and (max-width: 650px) {
        padding-left: ${(props) => props.theme.space[3]};
        padding-right: ${(props) => props.theme.space[3]};
    }
    @media only screen and (max-width: 450px) {
        padding-left: ${(props) => props.theme.space[2]};
        padding-right: ${(props) => props.theme.space[2]};
    }
    @media only screen and (max-width: 370px) {
        padding-left: ${(props) => props.theme.space[1]};
        padding-right: ${(props) => props.theme.space[1]};
    }
`;

