import React, { useEffect, useState } from 'react'
import { HeroSectionWrapper, HeroVideo } from '../style/home.styles'
import { useRef } from 'react'
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import { AbsoluteBottomCenter, Container, Sandwich } from '../../../global/style/global.styles'
import useWindowDimensions from '../../../global/components/screenSize'
import { Navbar } from '../../../global/components/navbar';
import videoHero from '../../../assets/videos/HeroVideo.mp4'

export const HeroSection = (props) => {

    const [scroll, setScroll] = useState(false);

    const handleScroll = () => {

        if (window.scrollY > props.height) {
            setScroll(true)
        } else {
            setScroll(false)
        }
    }

    useEffect(() => {
        window.addEventListener('scroll', handleScroll)
    }, []);

    const videoRef = useRef(undefined);

    useEffect(() => {
        if (!scroll) { videoRef.current.defaultMuted = true; }
    }, [])

    return (
        <>
            <HeroSectionWrapper>
                {
                    !scroll
                    &&
                    <HeroVideo
                        ref={videoRef}
                        loop
                        autoPlay
                        muted
                        playsInline
                        // src={props.content && props.content.video}
                        src={videoHero}
                    />
                }
                <Navbar
                    color="white"
                />
                <AbsoluteBottomCenter>
                    <Sandwich onClick={props.clickScroll}>
                        <ExpandMoreRoundedIcon
                            fontSize='large'
                            sx={{
                                color: 'white',
                                cursor: 'pointer'
                            }}
                        />
                    </Sandwich>
                </AbsoluteBottomCenter>
            </HeroSectionWrapper>
        </>
    )
}
