import { Button } from '@mui/material'
import React from 'react'
import { SpacerTopBottomXL, SpacerTopBottomXXXL } from '../../../design/spacer/spacer'
import { DividerHorizontal } from '../../../global/components/divider'
import { AlignCenter, BodySPDM, Container, FlexBoxSA, Sandwich, TitleGHM } from '../../../global/style/global.styles'
import { WhiteContainer } from '../style/home.styles'
import { ImagesCollage } from './imagesCollage'

export const ImageSection = (props) => {

    return (
        <>
            <WhiteContainer>
                <Container>
                    <Sandwich>
                        <AlignCenter>
                            <TitleGHM style={{ textTransform: 'uppercase' }}>
                                Here's to love, laughter, and happily ever after
                            </TitleGHM>
                            <SpacerTopBottomXL />
                            <BodySPDM style={{ color: '#D68808' }}>
                                Wedding Photos
                            </BodySPDM>
                            <SpacerTopBottomXXXL />
                            <DividerHorizontal
                                borderBottom={"2px dashed #BABABA"}
                                width={160}

                            />
                        </AlignCenter>
                    </Sandwich>
                    <ImagesCollage
                        content={props.content && props.content}
                        width={props.width}
                    />
                    <FlexBoxSA>
                        <Button
                            variant='contained'
                            size='small'
                            sx={{
                                textTransform: 'none',
                                bgcolor: '#404040',
                                color: 'white',
                                ':hover': {
                                    bgcolor: '#000000',
                                    color: 'white',
                                }
                            }}
                            onClick={props.navigate}>
                            View more
                        </Button>
                    </FlexBoxSA>
                </Container>
            </WhiteContainer>
        </>
    )
}
