import { Grid } from '@mui/material'
import React from 'react'
import { SpacerTopBottomXL, SpacerTopBottomXXL } from '../../../design/spacer/spacer'
import { AbsoluteCenter, Container, Sandwich, Squeezer, SubHeadingSDM } from '../../../global/style/global.styles'
import { AboutImg, AboutImgHolder, AboutUsText } from '../style/about.styles'
import aboutBg from '../../../assets/images/aboutBg.jpg'
import decorBg from '../../../assets/images/decor.jpg'
import { Layer } from '../../../global/components/layer'

export const AboutUs = (props) => {
    return (
        <Sandwich>
            <Squeezer>
                <SubHeadingSDM style={{ color: '#D68808' }}>
                    About Us
                </SubHeadingSDM>
                <SpacerTopBottomXXL />
                <div style={{ position: 'relative' }}>
                    <AboutUsText>
                        {props.content && props.content.brief}
                    </AboutUsText>
                    <AbsoluteCenter style={{ zIndex: -2 }}>
                        <Layer
                            shade={"rgba(255,255,255,0.9)"}
                        />
                        <AboutImgHolder style={{ width: props.width }}>
                            <AboutImg src={decorBg} />
                        </AboutImgHolder>
                    </AbsoluteCenter>
                </div>
                {/* <Grid container>
                    <Grid item xl={7} lg={7} md={10} sm={12} xs={12}>
                        <AboutUsText>
                            {props.content && props.content.brief}
                        </AboutUsText>
                    </Grid>
                    <Grid item xl={5} lg={5} md={2} sm={12} xs={12}>
                        <AboutImgHolder>
                            <AboutImg src={aboutBg} />
                        </AboutImgHolder>
                    </Grid>
                </Grid> */}
            </Squeezer>
        </Sandwich>
    )
}
