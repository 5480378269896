import React from 'react'
import useWindowDimensions from '../../global/components/screenSize';
import Lottie from 'react-lottie';
import notFoundGif from '../../assets/animations/404page.json'
import { AbsoluteCenter } from '../../global/style/global.styles';
import { Navbar } from '../../global/components/navbar';

const NotFound = () => {

  const { height, width } = useWindowDimensions();

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: notFoundGif,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

  return (
    <>
      <Navbar />
      <div>
        <AbsoluteCenter>
          <Lottie
            options={defaultOptions}
          />
        </AbsoluteCenter>
      </div>
    </>
  )
}

export default NotFound