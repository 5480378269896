import React from 'react'
import { FlexBoxSA } from '../style/global.styles'

export const DividerHorizontal = (props) => {
    return (
        <>
            <FlexBoxSA>
                <div style={{
                    borderBottom: props.borderBottom,
                    width: props.width
                }}
                />
            </FlexBoxSA>
        </>
    )
}