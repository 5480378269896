import React, { useRef } from 'react'
import './style/home.css'
import { HeroSection } from './components/heroSection'
import { useLocation, useNavigate } from 'react-router-dom'
import { TestimonialSection } from './components/testimonialSection'
import { CtaSection } from './components/ctaSection'
import { Footer } from '../../global/components/footer'
import useWindowDimensions from '../../global/components/screenSize'
import { VideoSection } from './components/videoSection'
import ScrollToTop from '../../global/components/scrollToTop'
import { ImageSection } from './components/imageSection'
import { AbsoluteBottomRight } from '../../global/style/global.styles'
import { CtaBtnPersist } from '../../global/components/ctaBtnPersist'

export const Home = (props) => {

  const myRef = useRef(null);

  const navigate = useNavigate();
  const location = useLocation();

  const executeScroll = () => {
    myRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
  }


  return (
    <>
      <ScrollToTop />
      <HeroSection
        clickScroll={executeScroll}
        height={props.height}
        content={props.content.heroSection}
      />
      <VideoSection
        refProp={myRef}
        location={location}
        content={props.content.videoSection}
      />
      <ImageSection
        navigate={() => navigate("gallery/albums")}
        width={props.width}
        content={props.content.imageSection}
      />
      <TestimonialSection
        width={props.width}
        content={props.content.testimonialSection}
      />
      <CtaSection
        navigate={() => navigate("contact")}
        width={props.width}
        content={props.content.ctaSection}
        contact={props.content.contact}
      />
      <Footer />
      {
        props.scroll > 120
        &&
        <CtaBtnPersist
          clicked={() => props.navigate("/contact")}
        />
      }
    </>
  )
}