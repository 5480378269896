import React, { createContext, useState } from 'react'

export const ArchiveContext = createContext()

export const ArchiveProvider = ({ children }) => {


    return (
        <ArchiveContext.Provider
            value={{
                
            }}
        >
            {children}
        </ArchiveContext.Provider>
    )
}

