import { ErrorMessage, Field } from 'formik';
import styled from 'styled-components'

// ----------------------------------------CONTAINERS----------------------------------------//

export const Container = styled.div`
    padding-top: ${(props) => props.theme.space[10]};
    padding-bottom: ${(props) => props.theme.space[10]};
    padding-left: ${(props) => props.theme.space[20]};
    padding-right: ${(props) => props.theme.space[20]};
    
    @media only screen and (min-width: 1800px) {
        padding-left: ${(props) => props.theme.space[31]};
        padding-right: ${(props) => props.theme.space[31]};
    }
    @media only screen and (max-width: 1300px) {
        padding-left: ${(props) => props.theme.space[10]};
        padding-right: ${(props) => props.theme.space[10]};
    }
    @media only screen and (max-width: 1100px) {
        padding-left: ${(props) => props.theme.space[8]};
        padding-right: ${(props) => props.theme.space[8]};
    }
    @media only screen and (max-width: 850px) {
        padding-left: ${(props) => props.theme.space[10]};
        padding-right: ${(props) => props.theme.space[10]};
    }
    @media only screen and (max-width: 650px) {
        padding-top: ${(props) => props.theme.space[3]};
        padding-bottom: ${(props) => props.theme.space[3]};
        padding-left: ${(props) => props.theme.space[3]};
        padding-right: ${(props) => props.theme.space[3]};
    }
    @media only screen and (max-width: 450px) {
        padding-left: ${(props) => props.theme.space[2]};
        padding-right: ${(props) => props.theme.space[2]};
    }
    @media only screen and (max-width: 370px) {
        padding-left: ${(props) => props.theme.space[1]};
        padding-right: ${(props) => props.theme.space[1]};
    }
`;

export const Squeezer = styled.div`
    padding-left: ${(props) => props.theme.space[20]};
    padding-right: ${(props) => props.theme.space[20]};
    
    @media only screen and (min-width: 1800px) {
        padding-left: ${(props) => props.theme.space[31]};
        padding-right: ${(props) => props.theme.space[31]};
    }
    @media only screen and (max-width: 1300px) {
        padding-left: ${(props) => props.theme.space[10]};
        padding-right: ${(props) => props.theme.space[10]};
    }
    @media only screen and (max-width: 1100px) {
        padding-left: ${(props) => props.theme.space[8]};
        padding-right: ${(props) => props.theme.space[8]};
    }
    @media only screen and (max-width: 850px) {
        padding-left: ${(props) => props.theme.space[10]};
        padding-right: ${(props) => props.theme.space[10]};
    }
    @media only screen and (max-width: 650px) {
        padding-left: ${(props) => props.theme.space[3]};
        padding-right: ${(props) => props.theme.space[3]};
    }
    @media only screen and (max-width: 450px) {
        padding-left: ${(props) => props.theme.space[2]};
        padding-right: ${(props) => props.theme.space[2]};
    }
    @media only screen and (max-width: 370px) {
        padding-left: ${(props) => props.theme.space[1]};
        padding-right: ${(props) => props.theme.space[1]};
    }
`;

export const Sandwich = styled.div`
    padding-top: ${(props) => props.theme.space[5]};
    padding-bottom: ${(props) => props.theme.space[5]};

    @media only screen and (max-width: 650px) {
        padding-top: ${(props) => props.theme.space[3]};
        padding-bottom: ${(props) => props.theme.space[3]};
    }
`;

// ----------------------------------------TEXT----------------------------------------//

export const ChildHeadlineGHM = styled.div`
    font-size: ${(props) => props.theme.fontSizes.childHeadline};
    color: ${(props) => props.theme.colors.text.greyHigh};
    font-weight: ${(props) => props.theme.fontWeights.medium};

    @media only screen and (max-width: 850px) {
        font-size: 48px;
        padding: 0 40px;
    }
    @media only screen and (max-width: 650px) {
        font-size: ${(props) => props.theme.fontSizes.heading};
    }
    @media only screen and (max-width: 450px) {
        font-size: ${(props) => props.theme.fontSizes.subHeading};
        padding: 0 32px;
    }
    @media only screen and (max-width: 370px) {
        font-size: ${(props) => props.theme.fontSizes.title};
        padding: 0 20px;
    }
`;

export const SubHeadingSDM = styled.div`
    font-size: ${(props) => props.theme.fontSizes.subHeading};
    color: ${(props) => props.theme.colors.secondary.pastelDark};
    font-weight: ${(props) => props.theme.fontWeights.medium};
    letter-spacing: 0.4px;
`;

export const SubHeadingGMM = styled.div`
    font-size: ${(props) => props.theme.fontSizes.subHeading};
    color: ${(props) => props.theme.colors.text.greyMid};
    font-weight: ${(props) => props.theme.fontWeights.medium};
    letter-spacing: 0.4px;
`;

export const SubHeadingSBZilla = styled.div`
    font-family: 'Zilla Slab', serif;
    font-size: ${(props) => props.theme.fontSizes.subHeading};
    color: ${(props) => props.theme.colors.text.greyMid};
    font-weight: ${(props) => props.theme.fontWeights.semiBold};
`;

export const TitleGHM = styled.div`
    font-size: ${(props) => props.theme.fontSizes.title};
    color: ${(props) => props.theme.colors.text.greyHigh};
    font-weight: ${(props) => props.theme.fontWeights.medium};
    letter-spacing: 0.4px;
`;

export const TitleGHSBNun = styled.div`
    font-family: 'Nunito', sans-serif;
    font-size: ${(props) => props.theme.fontSizes.title};
    color: ${(props) => props.theme.colors.text.black};
    font-weight: ${(props) => props.theme.fontWeights.semiBold};
`;

export const SubTitleGHB = styled.div`
    font-size: ${(props) => props.theme.fontSizes.subTitle};
    color: ${(props) => props.theme.colors.text.greyHigh};
    font-weight: ${(props) => props.theme.fontWeights.bold};
`;

export const SubTitleGHSB = styled.div`
    font-size: ${(props) => props.theme.fontSizes.subTitle};
    color: ${(props) => props.theme.colors.text.greyHigh};
    font-weight: ${(props) => props.theme.fontWeights.semiBold};
`;

export const SubTitleGMM = styled.div`
    font-size: ${(props) => props.theme.fontSizes.subTitle};
    color: ${(props) => props.theme.colors.text.greyMid};
    font-weight: ${(props) => props.theme.fontWeights.medium};
`;

export const SubTitleMZilla = styled.div`
    font-family: 'Zilla Slab', serif;
    font-size: ${(props) => props.theme.fontSizes.subTitle};
    color: ${(props) => props.theme.colors.text.greyHigh};
    font-weight: ${(props) => props.theme.fontWeights.medium};
`;

export const SubTitleSBZilla = styled.div`
    font-family: 'Zilla Slab', serif;
    font-size: ${(props) => props.theme.fontSizes.subTitle};
    color: ${(props) => props.theme.colors.text.greyHigh};
    font-weight: ${(props) => props.theme.fontWeights.semiBold};
`;

export const SubTitle = styled.div`
    font-size: ${(props) => props.theme.fontSizes.subTitle};
    color: ${(props) => props.theme.colors.text.greyHigh};
    font-weight: ${(props) => props.theme.fontWeights.regular};
`;

export const SubTitleGLM = styled.div`
    font-size: ${(props) => props.theme.fontSizes.subTitle};
    color: ${(props) => props.theme.colors.text.greyLow};
    font-weight: ${(props) => props.theme.fontWeights.medium};
`;

export const SubTitleSPDM = styled.div`
    font-size: ${(props) => props.theme.fontSizes.subTitle};
    color: ${(props) => props.theme.colors.secondary.pastelDark};
    font-weight: ${(props) => props.theme.fontWeights.medium};
`;

export const SubTitleGMI = styled.div`
    font-size: ${(props) => props.theme.fontSizes.subTitle};
    color: ${(props) => props.theme.colors.text.greyMid};
    font-weight: ${(props) => props.theme.fontWeights.regular};
    font-style: italic;
    letter-spacing: 0.4px;
    line-height: 32px;
`;

export const BodyGMI = styled.div`
    font-size: ${(props) => props.theme.fontSizes.body};
    color: ${(props) => props.theme.colors.text.greyMid};
    font-weight: ${(props) => props.theme.fontWeights.regular};
    font-style: italic;
    letter-spacing: 0.4px;
    line-height: 24px;
`;

export const BodyGH = styled.div`
    font-size: ${(props) => props.theme.fontSizes.body};
    color: ${(props) => props.theme.colors.text.greyHigh};
    font-weight: ${(props) => props.theme.fontWeights.regular};
`;

export const BodyGHSB = styled.div`
    font-size: ${(props) => props.theme.fontSizes.body};
    color: ${(props) => props.theme.colors.text.greyHigh};
    font-weight: ${(props) => props.theme.fontWeights.semiBold};
`;

export const BodyGL = styled.div`
    font-size: ${(props) => props.theme.fontSizes.body};
    color: ${(props) => props.theme.colors.text.greyLow};
    font-weight: ${(props) => props.theme.fontWeights.regular};
`;

export const BodyGM = styled.div`
    font-size: ${(props) => props.theme.fontSizes.body};
    color: ${(props) => props.theme.colors.text.greyMid};
    font-weight: ${(props) => props.theme.fontWeights.regular};
`;

export const BodyW = styled.div`
    font-size: ${(props) => props.theme.fontSizes.body};
    color: ${(props) => props.theme.colors.text.white};
    font-weight: ${(props) => props.theme.fontWeights.regular};
`;

export const BodySPDM = styled.div`
    font-size: ${(props) => props.theme.fontSizes.body};
    color: ${(props) => props.theme.colors.secondary.pastelDark};
    font-weight: ${(props) => props.theme.fontWeights.medium};
`;

export const BodyMNun = styled.div`
    font-family: 'Nunito', sans-serif;
    font-size: ${(props) => props.theme.fontSizes.body};
    color: ${(props) => props.theme.colors.text.black};
    font-weight: ${(props) => props.theme.fontWeights.medium};
`;

export const SubBodySBNun = styled.div`
    font-family: 'Nunito', sans-serif;
    font-size: ${(props) => props.theme.fontSizes.subBody};
    color: ${(props) => props.theme.colors.text.black};
    font-weight: ${(props) => props.theme.fontWeights.semiBold};
`;

export const SubBodyGLSBLNun = styled.div`
    font-family: 'Nunito', sans-serif;
    font-size: ${(props) => props.theme.fontSizes.subBody};
    color: ${(props) => props.theme.colors.text.greyLow};
    font-weight: ${(props) => props.theme.fontWeights.semiBold};
    font-style: italic;
`;

export const SubBodyGMSBNun = styled.div`
    font-family: 'Nunito', sans-serif;
    font-size: ${(props) => props.theme.fontSizes.subBody};
    color: ${(props) => props.theme.colors.text.greyMedium};
    font-weight: ${(props) => props.theme.fontWeights.semiBold};
`;

// ----------------------------------------LAYER----------------------------------------//

export const LayerWrapper = styled.div`
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
`;

// ----------------------------------------FLEX----------------------------------------//

export const FlexBoxSB = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const FlexBoxSA = styled.div`
    display: flex;
    justify-content: space-around;
    align-items: center;
`;

export const FlexBoxSE = styled.div`
    display: flex;
    justify-content: space-evenly;
    align-items: center;
`;

export const FlexBox = styled.div`
    display: flex;
    align-items: center;
`;

export const FlexColumn = styled.div`
    display: flex;
    flex-direction: column;
`;

export const FlexColumnSA = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
`;

export const FlexColumnSB = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

export const FlexColumnSE = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
`;

export const FlexPageCenter = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 100vh;
`;

export const FlexRowEnd = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
`;

// ----------------------------------------ALIGNMENT----------------------------------------//

export const AlignCenter = styled.div`
    text-align: center;
`;

export const AlignRight = styled.div`
    text-align: right;
`;

export const TopLeft = styled.div`
    position: absolute;
    top: 0;
    left: 0;
`;

export const AbsoluteCenter = styled.div`
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
`;

export const AbsoluteBottomRight = styled.div`
    position: absolute;
    right: 0;
    bottom: 0;
`;

export const AbsoluteTopCenter = styled.div`
    position: absolute;
    left: 50%;
    top: 0;
    transform: translate(-50%,0);
`;

export const AbsoluteBottomCenter = styled.div`
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translate(-50%,0);
`;

export const AbsoluteRightCenter = styled.div`
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(0,-50%);
`;

// ----------------------------------------NAVBAR----------------------------------------//

export const HamburgerContainer = styled.div`
    background-color: ${(props) => props.theme.colors.ui.black};
    border-radius: 50%;
    padding: 8px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    cursor: pointer;
`;

export const MenuContainer = styled.div`
    padding-top: ${(props) => props.theme.space[6]};
    padding-bottom: ${(props) => props.theme.space[6]};
    padding-left: ${(props) => props.theme.space[3]};
    padding-right: ${(props) => props.theme.space[3]};
    height: 90vh;

    @media only screen and (max-width: 370px) {
        height: 80vh;
    }
`;

export const NavLogoHolder = styled.div`
    width: 64px;
    height: 64px;
`;

export const NavLogo = styled.img`
    width: 100%;
    height: 100%;
`;

// export const NavbarLeft = styled.div`
//     display: flex;
//     -webkit-justify-content: flex-end;
// `;

// ----------------------------------------PAGESTART----------------------------------------//

export const PageStartContainer = styled.div`
    padding-top: 120px;

    @media only screen and (max-width: 850px) {
        padding-top: 0;
    }
`;

// ----------------------------------------FORM----------------------------------------//

export const FormInput = styled(Field)`
    height: 40px;
    border: 1px solid #CCCCCC;
    padding: 2px 4px;
    width: 100%;
    border-radius: 4px;
    font-size: 16px;
`;

export const FormInputTextarea = styled.textarea`
    border: 1px solid #CCCCCC;
    width: 100%;
    padding: 4px 8px;
    border-radius: 4px;
    resize: none;
    vertical-align: top
`;

export const FormError = styled(ErrorMessage)`
    color: red;
    font-size: ${(props) => props.theme.fontSizes.caption};
`;


// ----------------------------------------FOOTER----------------------------------------//

export const FooterWrapper = styled.div`
    background-color: ${(props) => props.theme.colors.bg.pastel};
`;

export const FooterLinkItem = styled.div`
    font-size: ${(props) => props.theme.fontSizes.subTitle};
    color: ${(props) => props.theme.colors.text.greyHigh};
    font-weight: ${(props) => props.theme.fontWeights.medium};
    cursor: pointer;
`;

// ----------------------------------------MISC----------------------------------------//

export const SocialIconHolder = styled.div`
    width: 40px;
    height: 40px;

    @media only screen and (max-width: 450px) {
        width: 32px;
        height: 32px;
    }
`;

export const SocialIcon = styled.img`
    width: 100%;
    height: 100%;
`;

