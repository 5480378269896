import React from 'react'
import './style/gallery.css'
import { Footer } from '../../global/components/footer'
import ScrollToTop from '../../global/components/scrollToTop'
import { GalleryNav } from './components/galleryNav'
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import useWindowDimensions from '../../global/components/screenSize'
import { Navbar } from '../../global/components/navbar'
import { CtaBtnPersist } from '../../global/components/ctaBtnPersist'

export const Gallery = (props) => {

  const location = useLocation();
  const navigate = useNavigate();

  return (
    <>
      <ScrollToTop />
      <Navbar
        color="black"
      />
      {/* <GalleryNav
        data={GALLERYNAVDATA}
        location={location}
        navigate={navigate}
      /> */}
      <Outlet />
      <Footer />
      <CtaBtnPersist
        clicked={() => props.navigate("/contact")}
      />
    </>
  )
}