import React, { useState } from 'react'
import { AbsoluteBottomCenter, AbsoluteBottomRight, AbsoluteCenter, BodyGHSB, BodyMNun, BodyW, Container, FlexBox, Sandwich, SubBodyGMSBNun } from '../../../global/style/global.styles'
import { AlbumBlockContainer, AlbumThumbnail, AlbumThumbnailHolder, CoverHover, TitleHolder } from '../style/gallery.styles'
import PhotoLibraryRoundedIcon from '@mui/icons-material/PhotoLibraryRounded';
import { SpacerLeftRightMedium, SpacerLeftRightSmall } from '../../../design/spacer/spacer';
import { AlbumDetailContainer } from '../../home/style/home.styles';

export const AlbumBlock = (props) => {

    const [hover, setHover] = useState(false);



    return (
        <>
            <AlbumBlockContainer
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
                onClick={() => props.navigate(`/gallery/${props.id}`)}>
                <AlbumThumbnailHolder>
                    <AlbumThumbnail src={props.thumbnail} />
                    <AbsoluteBottomCenter>
                        <Sandwich>
                            <AlbumDetailContainer>
                                <FlexBox>
                                    <PhotoLibraryRoundedIcon
                                        sx={{
                                            color: '#6A6A6A'
                                        }}
                                    />
                                    <SpacerLeftRightSmall />
                                    <BodyGHSB>
                                        {props.numofImgs}
                                    </BodyGHSB>
                                </FlexBox>
                            </AlbumDetailContainer>
                        </Sandwich>
                    </AbsoluteBottomCenter>
                </AlbumThumbnailHolder>
                {
                    hover
                    &&
                    <AbsoluteCenter>
                        <CoverHover>
                            <AbsoluteCenter>
                                <BodyW style={{ textDecoration: "underline" }}>
                                    VIEW ALBUM
                                </BodyW>
                            </AbsoluteCenter>
                        </CoverHover>
                    </AbsoluteCenter>
                }
            </AlbumBlockContainer>
        </>
    )
}
