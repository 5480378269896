import React from 'react'
import { Sandwich } from '../../../global/style/global.styles'

export const Recognition = () => {
    return (
        <>
            <Sandwich />
        </>
    )
}
