import React from 'react'
import Lottie from 'react-lottie'
import { SpacerTopBottomMedium } from '../../../design/spacer/spacer'
import { Navbar } from '../../../global/components/navbar'
import { AbsoluteCenter, AlignCenter, ChildHeadlineGHM, Sandwich, Squeezer } from '../../../global/style/global.styles'
import { AboutHeroImg, AboutHeroWrapper, AboutLayer } from '../style/about.styles'
import brandGraphics from '../../../assets/animations/graphics.json'

export const AboutHeroSection = (props) => {

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: brandGraphics,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };

    return (
        <>
            <AboutHeroWrapper>
                {/* <AboutHeroImg src={props.heroImg} /> */}
                <AbsoluteCenter style={{ zIndex: -1 }}>
                    <Lottie
                        options={defaultOptions}
                        width={
                            props.width > 850
                                ?
                                props.width
                                :
                                props.width * 2
                        }
                    />
                </AbsoluteCenter>
                <AboutLayer>
                    <Navbar
                        color={props.navColor}
                    />
                    <AbsoluteCenter>
                        <AlignCenter>
                            <ChildHeadlineGHM>
                                For us, the camera is a sketchbook, an instrument of intuition and spontaneity.
                            </ChildHeadlineGHM>
                            {/* <SpacerTopBottomMedium />
                            <ChildHeadlineGHM>
                                Some text to potray company values
                            </ChildHeadlineGHM> */}
                        </AlignCenter>
                    </AbsoluteCenter>
                </AboutLayer>
            </AboutHeroWrapper>
        </>
    )
}
