import React from 'react'
import { Footer } from '../../global/components/footer'
import { Navbar } from '../../global/components/navbar'
import ScrollToTop from '../../global/components/scrollToTop'
import { AboutHeroSection } from './components/aboutHeroSection'
import LOGO from '../../assets/images/logo.png'
import { AboutUs } from './components/aboutUs'
import { Recognition } from './components/recognition'
import useWindowDimensions from '../../global/components/screenSize'
import { CtaBtnPersist } from '../../global/components/ctaBtnPersist'

export const About = (props) => {

  const { width } = useWindowDimensions();

  return (
    <>
      <ScrollToTop />
      <AboutHeroSection
        heroImg={LOGO}
        navColor={"black"}
        width={width}
      />
      <AboutUs
        content={props.content.about}
        width={width}
      />
      <Recognition />
      <Footer />
      <CtaBtnPersist
        clicked={() => props.navigate("/contact")}
      />
    </>
  )
}
