import React, { useEffect, useState } from 'react'
import { SpacerTopBottomLarge } from '../../../design/spacer/spacer'
import { Navbar } from '../../../global/components/navbar'
import { AbsoluteBottomCenter, BodyW, Sandwich } from '../../../global/style/global.styles'
import { AlbumCoverLayer, AlbumHeroImg, AlbumHeroSectionWrapper } from '../style/gallery.styles'

export const AlbumHeroSection = (props) => {

    const [scroll, setScroll] = useState(false);

    const handleScroll = () => {

        if (window.scrollY > props.height) {
            setScroll(true)
        } else {
            setScroll(false)
        }
    }

    useEffect(() => {
        window.addEventListener('scroll', handleScroll)
    }, []);

    return (
        <>
            <AlbumHeroSectionWrapper>
                {
                    !scroll
                    &&
                    <AlbumHeroImg src={props.heroImg} />
                }
                <AlbumCoverLayer>
                    <Navbar
                        color={props.navColor}
                    />
                    <AbsoluteBottomCenter>
                        <BodyW
                            style={{ textDecoration: 'underline', cursor: 'pointer' }}
                            onClick={props.scroll}>
                            VIEW GALLERY
                        </BodyW>
                        <Sandwich />
                        {
                            props.width < 850
                            &&
                            <>
                                <Sandwich />
                                <Sandwich />
                            </>
                        }
                    </AbsoluteBottomCenter>
                </AlbumCoverLayer>
            </AlbumHeroSectionWrapper>
        </>
    )
}
