export const colors = {
  primary: {
    white: "#FFFFFF"
  },
  secondary: {
    pastelDark: "#CAAF83",
    pastelMid: "#FFD9A3",
    pastelLite: "#FFF0DB"
  },
  bg: {
    black: "#000000",
    white: "#FFFFFF",
    pastel: "#FFFCF8",
  },
  ui: {
    black: "#000000",
    grey80: "#242424",
    grey60: "#6A6A6A",
    grey40: "#BABABA",
    grey20: "#EEEEEE",
    white: "#FFFFFF"
  },
  text: {
    black: "#000000",
    white: "#FFFFFF",
    greyHigh: "#242424",
    greyMid: "#6A6A6A",
    greyLow: "#BABABA",
    greyLite: "#EEEEEE",
    link: "#2998FF",
    highlight: "#FFE83A",
    green: "#00EC22",
    red: "#FF2929"
  }
};