import styled from 'styled-components'

// ----------------------------------------CONTAINERS----------------------------------------//

export const Parallelogram = styled.div`
    height: 96px;
    margin-right: 20px;
    -webkit-transform: skew(-20deg);
    -moz-transform: skew(-20deg);
    -o-transform: skew(-20deg);
    overflow: hidden;
    position: relative;
    cursor: pointer;

    &:hover {
        background: rgb(255,240,219);
        background: radial-gradient(circle, rgba(255,240,219,1) 0%, rgba(255,255,255,1) 100%);
    }

    @media only screen and (max-width: 850px) {
        &:hover {
            background: rgb(255,255,255);
        }
    }
`;

export const ParallelogramSelected = styled.div`
    height: 96px;
    margin-right: 20px;
    -webkit-transform: skew(-20deg);
    -moz-transform: skew(-20deg);
    -o-transform: skew(-20deg);
    overflow: hidden;
    position: relative;
    background: rgb(255,240,219);
    background: radial-gradient(circle, rgba(255,240,219,1) 0%, rgba(255,255,255,1) 100%);

`;

export const ParallelogramText = styled.div`
    font-size: ${(props) => props.theme.fontSizes.title};
    color: ${(props) => props.theme.colors.text.greyLow};
    font-weight: ${(props) => props.theme.fontWeights.semiBold};
    font-style: italic;
    -webkit-transform: skew(20deg);
    -moz-transform: skew(20deg);
    -o-transform: skew(20deg);
    padding-right: 20px;
`;

export const AlbumBlockContainer = styled.div`
    position: relative;
    cursor: pointer;
    background-color: #EEEEEE;

    &:hover {
        transform: scale(1.01);
        transition: all 0.4s ease;
        box-shadow: 0px 2px 6px 2px rgba(189,189,189,0.75);
-webkit-box-shadow: 0px 2px 6px 2px rgba(189,189,189,0.75);
-moz-box-shadow: 0px 2px 6px 2px rgba(189,189,189,0.75);
    }
    &:not(:hover) {
        transform: scale(1);
        transition: all 0.4s ease;
    }
`;

export const AlbumsContainer = styled.div`
    
`;

export const AlbumThumbnailHolder = styled.div`
    width: 280px;
    height: 360px;
`;

export const AlbumThumbnail = styled.img`
    width: 100%;
    height: 100%;
`;

export const CoverHover = styled.div`
    background-color: rgba(0,0,0,0.4);
    width: 280px;
    height: 360px;
    position: relative;
`;

export const AlbumHeroSectionWrapper = styled.div`
    height: 100vh;
    position: relative;
`;

export const AlbumHeroImg = styled.img`
    position: fixed;
    object-fit: cover;
    z-index: -1;
    width: 100%;
    height: 100%;
`;

export const AlbumCoverLayer = styled.div`
    background-color: rgba(0,0,0,0.4);
    height: 100vh;
`;

export const AlbumNavContainer = styled.div`
    padding-top: ${(props) => props.theme.space[4]};
    padding-bottom: ${(props) => props.theme.space[4]};
    background-color: ${(props) => props.theme.colors.ui.white};
`;

export const AlbumGalleryContainer = styled.div`
    background-color: ${(props) => props.theme.colors.ui.white};
`;

