import { Grid } from '@mui/material'
import React, { useContext } from 'react'
import ReactPlayer from 'react-player'
import { ModalContext } from '../../../context/interface/modal.provider'
import { Container, FlexBoxSA, Sandwich, Squeezer } from '../../../global/style/global.styles'
import { VideoBlock } from '../../home/components/videoBlock'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { useLocation } from 'react-router-dom'
import useWindowDimensions from '../../../global/components/screenSize'

export const FilmsComp = (props) => {

    const { width } = useWindowDimensions();

    const location = useLocation();

    const {
        filmsVideoModal,
        tempUrl,
        playing,
        closeVideo
    } = useContext(ModalContext);

    return (
        <>
            <div className={filmsVideoModal ? "modal open" : "modal"}>
                <ReactPlayer
                    url={tempUrl}
                    playing={playing}
                    controls={true}
                    width={"100%"}
                    height={"100%"}
                />
                <CloseRoundedIcon onClick={closeVideo} />
            </div>
            <Sandwich>
                <Squeezer>
                    <Grid container>
                        {
                            props.data.map((item, index) => (
                                <Grid item xl={4} lg={6} md={6} sm={12} xs={12} key={index}>
                                    <FlexBoxSA>
                                        <VideoBlock
                                            key={index}
                                            id={item.id}
                                            url={item.url}
                                            thumbnail={item.thumbnail}
                                            location={location}
                                        />
                                    </FlexBoxSA>
                                </Grid>
                            ))
                        }
                    </Grid>
                </Squeezer>
            </Sandwich>
        </>
    )
}
