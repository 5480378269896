import * as yup from "yup";

export const ContactFormSchema = yup.object().shape({

    firstName: yup.string()
        .required("Firstname is required"),

    lastName: yup.string()
        .notRequired(),

    phone: yup.string()
        .required(),

    email: yup.string().email().required("Email is required"),

    date: yup.string()
        .notRequired(),

});