import { CircularProgress, Grid } from '@mui/material'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { SpacerTopBottomLarge, SpacerTopBottomMedium } from '../../../design/spacer/spacer'
import { AbsoluteCenter, Container, FlexBoxSA, Sandwich, Squeezer } from '../../../global/style/global.styles'
import { AlbumBlock } from '../components/albumBlock'
import { AlbumsContainer } from '../style/gallery.styles'


export const Albums = (props) => {

    const navigate = useNavigate();

    const [addAlbum, setAddAlbum] = useState(false);

    return (
        <Sandwich>
            {
                props.loadingContent
                    ?
                    <Container style={{ position: 'relative' }}>
                        <AbsoluteCenter>
                            <CircularProgress />
                        </AbsoluteCenter>
                    </Container>
                    :
                    <FlexBoxSA>
                        <Grid container spacing={2} width={props.width > 850 ? 888 : 590}>
                            {
                                props.content.gallery
                                &&
                                props.content.gallery.albums.map((item, index) => (
                                    <Grid item xl={4} lg={4} md={4} sm={6} xs={12} key={index}>
                                        <FlexBoxSA>
                                            <AlbumBlock
                                                key={index}
                                                id={item.id}
                                                title={item.title}
                                                thumbnail={item.thumbnail}
                                                cover={item.cover}
                                                navColor={item.navColor}
                                                date={item.date}
                                                images={item.images}
                                                videos={item.videos}
                                                navigate={navigate}
                                                numofImgs={item.images.length}
                                            />
                                        </FlexBoxSA>
                                    </Grid>
                                ))
                            }
                        </Grid>
                    </FlexBoxSA>
            }
        </Sandwich>
    )
}
