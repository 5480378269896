import { Button, Grid, Tooltip } from '@mui/material'
import React from 'react'
import { SpacerLeftRightMedium, SpacerTopBottomLarge, SpacerTopBottomMedium } from '../../../design/spacer/spacer'
import { Container, FlexBox, FlexBoxSA, FlexBoxSB, FlexColumnSA, FlexColumnSB, SocialIcon, SocialIconHolder, SubHeadingSBZilla, SubTitle, SubTitleM, SubTitleMZilla, SubTitleSB, SubTitleSPDM, TitleGHM } from '../../../global/style/global.styles'
import LOGO from '../../../assets/images/logo.png'
import facebookIcon from '../../../assets/images/facebook.png'
import instagramIcon from '../../../assets/images/instagram.png'
import { CtaLogo, CtaLogoHolder, CtaSectionWrapper, CtaText, CtaTitle } from '../style/home.styles'

export const CtaSection = (props) => {
    return (
        <>
            <Container>
                <Grid container height={
                    props.width > 850
                        ?
                        360
                        :
                        320
                }>
                    <Grid item xl={7} lg={7} md={6} sm={12} xs={12}>
                        <FlexBoxSB>
                            <FlexColumnSA style={{ height: '100%' }}>
                                <div>
                                    <CtaTitle>
                                        The Oyster Studios
                                    </CtaTitle>
                                    <SpacerTopBottomLarge />
                                    <FlexBox>
                                        <a href={props.contact && props.contact.facebook} target="_blank" style={{ textDecoration: 'none' }}>
                                            <Tooltip title={props.contact && props.contact.facebook.slice(12)}>
                                                <SocialIconHolder>
                                                    <SocialIcon src={facebookIcon} />
                                                </SocialIconHolder>
                                            </Tooltip>
                                        </a>
                                        <SpacerLeftRightMedium />
                                        <a href={props.contact && props.contact.instagram} target="_blank" style={{ textDecoration: 'none' }}>
                                            <Tooltip title={props.contact && props.contact.instagram.slice(12)}>
                                                <SocialIconHolder>
                                                    <SocialIcon src={instagramIcon} />
                                                </SocialIconHolder>
                                            </Tooltip>
                                        </a>
                                    </FlexBox>
                                </div>
                            </FlexColumnSA>
                            {
                                props.width < 850
                                &&
                                <CtaLogoHolder>
                                    <CtaLogo src={LOGO} />
                                </CtaLogoHolder>
                            }
                        </FlexBoxSB>
                    </Grid>
                    <Grid item xl={5} lg={5} md={6} sm={12} xs={12}
                        style={
                            props.width > 850
                                ?
                                { borderLeft: '2px solid #EEEEEE', paddingLeft: 32 }
                                :
                                { borderLeft: 0 }
                        }>
                        <FlexBoxSA style={{ height: '100%' }}>
                            <FlexColumnSB style={{ height: '100%' }}>
                                <div>
                                    {
                                        props.width > 850
                                        &&
                                        <FlexBoxSA>
                                            <CtaLogoHolder>
                                                <CtaLogo src={LOGO} />
                                            </CtaLogoHolder>
                                        </FlexBoxSA>
                                    }
                                    <SpacerTopBottomMedium />
                                    <CtaText>
                                    {props.content && props.content.brief}
                                    </CtaText>
                                </div>
                                <FlexBoxSA>
                                    <Button
                                        variant='outlined'
                                        sx={{
                                            textTransform: 'none',
                                            border: '1px solid #D68808',
                                            color: '#CAAF83',
                                            ':hover': {
                                                bgcolor: '#FFF0DB',
                                                border: '1px solid #CAAF83',
                                            }
                                        }}
                                        size='large'
                                        onClick={props.navigate}>
                                        <SubTitleSPDM style={{ color: '#D68808' }}>
                                            Contact Us
                                        </SubTitleSPDM>
                                    </Button>
                                </FlexBoxSA>
                            </FlexColumnSB>
                        </FlexBoxSA>
                    </Grid>
                </Grid>
            </Container>
        </>
    )
}
