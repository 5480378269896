import React, { useContext } from 'react'
import { AbsoluteCenter, SubBodySBNun, Container, FlexBox, FlexBoxSA, FlexBoxSB, HamburgerContainer, NavLogo, NavLogoHolder, Sandwich, Squeezer } from '../style/global.styles'
import LOGO from '../../assets/images/logo.png'
import useWindowDimensions from './screenSize'
import { useLocation, useNavigate } from 'react-router-dom'
import { Grid } from '@mui/material'
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import RightDrawer from './rightDrawer'
import { ApiContext } from '../../context/api/api.provider'

export const Navbar = (props) => {

  const { width } = useWindowDimensions();

  const navigate = useNavigate();
  const location = useLocation();

  const { content, loadingContent } = useContext(ApiContext);

  return (
    <>
      <Sandwich>
        {
          width > 650
            ?
            <Grid container alignItems="center">
              <Grid item xl={5.5} lg={5.5} md={5.5} sm={5.5} xs={5.5}>
                <FlexBox style={{
                  justifyContent: 'flex-end'
                }}>
                  <div style={{
                    padding: '16px 10px',
                    borderBottom: props.color === "black"
                      ?
                      '2px solid rgba(0, 0, 0, 0.2)'
                      :
                      '2px solid rgba(255, 255, 255, 0.4)'
                  }} />
                  <SubBodySBNun style={{
                    color: props.color,
                    padding: '0 8px 16px 8px',
                    cursor: 'pointer',
                    borderBottom: location.pathname === "/"
                      ?
                      `2px solid ${props.color}`
                      :
                      props.color === "black"
                        ?
                        '2px solid rgba(0, 0, 0, 0.2)'
                        :
                        '2px solid rgba(255, 255, 255, 0.4)'
                  }}
                    onClick={() => navigate("/")}>
                    HOME
                  </SubBodySBNun>
                  <div style={{
                    padding: '16px 32px',
                    borderBottom: props.color === "black"
                      ?
                      '2px solid rgba(0, 0, 0, 0.2)'
                      :
                      '2px solid rgba(255, 255, 255, 0.4)'
                  }} />
                  <SubBodySBNun style={{
                    color: props.color,
                    padding: '0 8px 16px 8px',
                    cursor: 'pointer',
                    borderBottom: location.pathname.includes("/about")
                      ?
                      `2px solid ${props.color}`
                      :
                      props.color === "black"
                        ?
                        '2px solid rgba(0, 0, 0, 0.2)'
                        :
                        '2px solid rgba(255, 255, 255, 0.4)'
                  }}
                    onClick={() => navigate("/about")}>
                    ABOUT
                  </SubBodySBNun>
                  <div style={{
                    padding: '16px 24px',
                    borderBottom: props.color === "black"
                      ?
                      '2px solid rgba(0, 0, 0, 0.2)'
                      :
                      '2px solid rgba(255, 255, 255, 0.4)'
                  }} />
                </FlexBox>
              </Grid>
              <Grid item xl={1} lg={1} md={1} sm={1} xs={1}>
                <FlexBoxSA>
                  <NavLogoHolder>
                    <NavLogo src={LOGO} />
                  </NavLogoHolder>
                </FlexBoxSA>
              </Grid>
              <Grid item xl={5.5} lg={5.5} md={5.5} sm={5.5} xs={5.5}>
                <FlexBox>
                  <div style={{
                    padding: '16px 24px',
                    borderBottom: props.color === "black"
                      ?
                      '2px solid rgba(0, 0, 0, 0.2)'
                      :
                      '2px solid rgba(255, 255, 255, 0.4)'
                  }} />
                  <SubBodySBNun style={{
                    color: props.color,
                    padding: '0 8px 16px 8px',
                    cursor: 'pointer',
                    borderBottom: location.pathname.includes("/gallery")
                      ?
                      `2px solid ${props.color}`
                      :
                      props.color === "black"
                        ?
                        '2px solid rgba(0, 0, 0, 0.2)'
                        :
                        '2px solid rgba(255, 255, 255, 0.4)'
                  }}
                    onClick={() => navigate("/gallery/albums")}>
                    GALLERY
                  </SubBodySBNun>
                  <div style={{
                    padding: '16px 32px',
                    borderBottom: props.color === "black"
                      ?
                      '2px solid rgba(0, 0, 0, 0.2)'
                      :
                      '2px solid rgba(255, 255, 255, 0.4)'
                  }} />
                  <SubBodySBNun style={{
                    color: props.color,
                    padding: '0 8px 16px 8px',
                    cursor: 'pointer',
                    borderBottom: location.pathname.includes("/contact")
                      ?
                      `2px solid ${props.color}`
                      :
                      props.color === "black"
                        ?
                        '2px solid rgba(0, 0, 0, 0.2)'
                        :
                        '2px solid rgba(255, 255, 255, 0.4)'
                  }}
                    onClick={() => navigate("/contact")}>
                    CONTACT
                  </SubBodySBNun>
                  <div style={{
                    padding: '16px 10px',
                    borderBottom: props.color === "black"
                      ?
                      '2px solid rgba(0, 0, 0, 0.2)'
                      :
                      '2px solid rgba(255, 255, 255, 0.4)'
                  }} />
                </FlexBox>
              </Grid>
            </Grid>
            :
            <RightDrawer
              contact={content.contact}
            />
        }
      </Sandwich>
    </>
  )
}
