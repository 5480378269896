import React from 'react'
import { SpacerTopBottomLarge, SpacerTopBottomXL, SpacerTopBottomXXL, SpacerTopBottomXXXL } from '../../../design/spacer/spacer'
import { AbsoluteCenter, AbsoluteRightCenter, AlignCenter, FlexBoxSA, Sandwich, Squeezer, SubHeadingSDM } from '../../../global/style/global.styles'
import { ContactDetailsContainer, MapContainer } from '../style/contact.styles'
import { ContactInfo } from './contactInfo'

export const ContactDetails = (props) => {

    return (
        <ContactDetailsContainer>
            <SubHeadingSDM style={{ color: '#D68808' }}>
                Contact Us
            </SubHeadingSDM>
            <SpacerTopBottomXXXL />
            <MapContainer>
                <ContactInfo
                    contact={props.contact}
                    width={props.width}
                />
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3889.585282150745!2d77.5863423!3d12.8700412!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae6ac98ad7fc07%3A0x36ff663119d450cb!2sValmark%20Orchard%20Square!5e0!3m2!1sen!2sin!4v1675074614041!5m2!1sen!2sin" width="100%" height="100%" style={{ border: 0 }} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
            </MapContainer>
        </ContactDetailsContainer>
    )
}
