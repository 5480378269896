export const space = [
  "4px",
  "8px",
  "16px",
  "24px",
  "32px",
  "40px",
  "48px",
  "56px",
  "64px",
  "72px",
  "80px",
  "88px",
  "96px",
  "104px",
  "112px",
  "120px",
  "128px",
  "136px",
  "144px",
  "152px",
  "160px",
  "168px",
  "176px",
  "184px",
  "192px",
  "200px",
  "208px",
  "216px",
  "224px",
  "232px",
  "240px",
  "248px"
];