import React, { useContext, useState } from 'react'
import { InfoPart, Thumbnail, VideoBlockContainer, VideoBlockWrapper } from '../style/home.styles'
import PlayCircleOutlineRoundedIcon from '@mui/icons-material/PlayCircleOutlineRounded';
import { FlexBox } from '../../../global/style/global.styles';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { Backdrop, Fade, Modal } from '@mui/material';
import { Box } from '@mui/system';
import { ModalContext } from '../../../context/interface/modal.provider';

export const VideoBlock = (props) => {

    const {
        openFilmsVideo,
        openHomeVideo
    } = useContext(ModalContext);

    return (
        <>
            <VideoBlockWrapper>
                <VideoBlockContainer onClick={
                    props.location.pathname.includes("/gallery")
                        ?
                        () => openFilmsVideo(props.url)
                        :
                        () => openHomeVideo(props.url)
                }>
                    <Thumbnail src={props.thumbnail} />
                    <InfoPart>
                        <FlexBox style={{ justifyContent: "end" }}>
                            <PlayCircleOutlineRoundedIcon
                                sx={{
                                    color: 'rgba(255, 255, 255, 0.9)'
                                }}
                                fontSize='large'
                            />
                        </FlexBox>
                    </InfoPart>
                </VideoBlockContainer>
            </VideoBlockWrapper>
        </>
    )
}
