import { Grid, Tooltip } from '@mui/material'
import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { SpacerLeftRightLarge, SpacerLeftRightMedium, SpacerLeftRightSmall, SpacerLeftSmall, SpacerTopBottomLarge, SpacerTopBottomMedium, SpacerTopBottomSmall, SpacerTopBottomXXL, SpacerTopBottomXXXL } from '../../design/spacer/spacer'
import { AlignCenter, BodyGL, BodyGM, Container, FlexBox, FlexBoxSA, FlexBoxSB, FooterLinkItem, FooterWrapper, SubTitleGL, SubTitleGMM, SubTitleM } from '../style/global.styles'
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
import InstagramIcon from '@mui/icons-material/Instagram';
import CopyrightOutlinedIcon from '@mui/icons-material/CopyrightOutlined';
import FiberManualRecordRoundedIcon from '@mui/icons-material/FiberManualRecordRounded';
import useWindowDimensions from './screenSize'
import { ApiContext } from '../../context/api/api.provider'

export const Footer = () => {

  const { width } = useWindowDimensions()

  const navigate = useNavigate();

  const { content, loadingContent } = useContext(ApiContext);

  return (
    <>
      <FooterWrapper>
        <Container>
          <Grid container>
            <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
              <FlexBoxSA>
                <AlignCenter>
                  <FooterLinkItem onClick={() => navigate("/")}>
                    Home
                  </FooterLinkItem>
                  <SpacerTopBottomLarge />
                  <FooterLinkItem onClick={() => navigate("/about")}>
                    About
                  </FooterLinkItem>
                  <SpacerTopBottomLarge />
                  <FooterLinkItem onClick={() => navigate("/gallery/albums")}>
                    Gallery
                  </FooterLinkItem>
                </AlignCenter>
              </FlexBoxSA>
              {
                width < 850
                &&
                <SpacerTopBottomXXL />
              }
            </Grid>
            <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
              <FlexBoxSA>
                <AlignCenter>
                  <FooterLinkItem onClick={() => navigate("/gallery/albums")}>
                    Engagement
                  </FooterLinkItem>
                  <SpacerTopBottomLarge />
                  <FooterLinkItem onClick={() => navigate("/gallery/albums")}>
                    Haldi
                  </FooterLinkItem>
                  <SpacerTopBottomLarge />
                  <FooterLinkItem onClick={() => navigate("/gallery/albums")}>
                    Cocktail
                  </FooterLinkItem>
                  <SpacerTopBottomLarge />
                  <FooterLinkItem onClick={() => navigate("/gallery/albums")}>
                    Wedding
                  </FooterLinkItem>
                  <SpacerTopBottomLarge />
                  <FooterLinkItem onClick={() => navigate("/gallery/albums")}>
                    Reception
                  </FooterLinkItem>
                </AlignCenter>
              </FlexBoxSA>
              {
                width < 850
                &&
                <SpacerTopBottomXXL />
              }
            </Grid>
            <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
              <FlexBoxSA>
                <AlignCenter>
                  <a href='https://www.theoysterstudios.com/sitemap.xml' target="_blank" style={{ textDecoration: 'none' }}>
                    <FooterLinkItem>
                      Sitemap
                    </FooterLinkItem>
                  </a>
                  <SpacerTopBottomLarge />
                  <a href='/attribution' target="_blank" style={{ textDecoration: 'none' }}>
                    <FooterLinkItem>
                      Attribution
                    </FooterLinkItem>
                  </a>
                  <SpacerTopBottomLarge />
                  <a href='/privacyPolicy' target="_blank" style={{ textDecoration: 'none' }}>
                    <FooterLinkItem>
                      Privacy Policy
                    </FooterLinkItem>
                  </a>
                  <SpacerTopBottomLarge />
                  <a href='/terms' target="_blank" style={{ textDecoration: 'none' }}>
                    <FooterLinkItem>
                      Terms & Conditions
                    </FooterLinkItem>
                  </a>
                  <SpacerTopBottomLarge />
                  <a href='/cookies' target="_blank" style={{ textDecoration: 'none' }}>
                    <FooterLinkItem>
                      Cookies
                    </FooterLinkItem>
                  </a>
                </AlignCenter>
              </FlexBoxSA>
              {
                width < 850
                &&
                <SpacerTopBottomMedium />
              }
            </Grid>
          </Grid>
          <SpacerTopBottomLarge />
          <FlexBoxSA>
            <div>
              <AlignCenter>
                <FooterLinkItem onClick={() => navigate("/contact")}>
                  Contact
                </FooterLinkItem>
              </AlignCenter>
              <SpacerTopBottomMedium />
              <Grid container>
                <Grid item xl={6} lg={6} md={6} sm={12} xs={12}
                  style={
                    width > 850
                      ?
                      { padding: '4px 8px', borderRight: '1px solid #BABABA' }
                      :
                      { padding: '4px 8px', borderRight: 0 }
                  }>
                  <FlexBox style={
                    width > 850
                      ?
                      { justifyContent: 'end' }
                      :
                      { justifyContent: 'space-around' }
                  }>
                    <FooterLinkItem>
                      {content.contact && content.contact.phone}
                    </FooterLinkItem>
                  </FlexBox>
                </Grid>
                <Grid item xl={6} lg={6} md={6} sm={12} xs={12}
                  style={
                    width > 850
                      ?
                      { padding: '4px 8px', borderLeft: '1px solid #BABABA' }
                      :
                      { padding: '4px 8px', borderRight: 0 }
                  }>
                  <FlexBox style={
                    width > 850
                      ?
                      { justifyContent: 'start' }
                      :
                      { justifyContent: 'space-around' }
                  }>
                    <FooterLinkItem>
                      {content.contact && content.contact.email}
                    </FooterLinkItem>
                  </FlexBox>
                </Grid>
              </Grid>
              <SpacerTopBottomMedium />
              <FlexBoxSA>
                <FlexBoxSB>
                  <a href={content.contact && content.contact.facebook} target="_blank" style={{ textDecoration: 'none' }}>
                    <Tooltip title={content.contact && content.contact.facebook.slice(12)}>
                      <FacebookOutlinedIcon
                        sx={{
                          color: '#242424',
                          fontSize: 40
                        }}
                      />
                    </Tooltip>
                  </a>
                  <SpacerLeftRightMedium />
                  <a href={content.contact && content.contact.instagram} target="_blank" style={{ textDecoration: 'none' }}>
                    <Tooltip title={content.contact && content.contact.instagram.slice(12)}>
                      <InstagramIcon
                        sx={{
                          color: '#242424',
                          fontSize: 40
                        }}
                      />
                    </Tooltip>
                  </a>
                </FlexBoxSB>
              </FlexBoxSA>
            </div>
          </FlexBoxSA>
          <SpacerTopBottomXXXL />
          <FlexBoxSA>
            {
              width > 450
                ?
                <FlexBox>
                  <CopyrightOutlinedIcon sx={{ color: '#242424' }} />
                  <SpacerLeftSmall />
                  <BodyGM>{new Date().getFullYear()} The Oyster Studios</BodyGM>
                  <SpacerLeftRightMedium>
                    <FiberManualRecordRoundedIcon sx={{ fontSize: 8, color: '#242424' }} />
                  </SpacerLeftRightMedium>
                  <BodyGM>All rights reserved</BodyGM>
                </FlexBox>
                :
                <div>
                  <FlexBox>
                    <CopyrightOutlinedIcon sx={{ color: '#242424' }} />
                    <SpacerLeftSmall />
                    <BodyGM>{new Date().getFullYear()} The Oyster Studios</BodyGM>
                  </FlexBox>
                  <SpacerTopBottomSmall />
                  <FlexBoxSA>
                    <BodyGM>All rights reserved</BodyGM>
                  </FlexBoxSA>
                </div>
            }
          </FlexBoxSA>
          <SpacerTopBottomLarge />
          <AlignCenter>
            <a href='https://inspirise.com/' target="_blank" style={{ textDecoration: 'none' }}>
              <BodyGM>
                Site by Inspirise
              </BodyGM>
            </a>
          </AlignCenter>
          <SpacerTopBottomLarge />
          <AlignCenter>
            <BodyGL>
              This site is protected by reCAPTCHA and the Google <a href="https://policies.google.com/privacy" style={{ color: '#4EAAFF' }}>Privacy Policy</a> and <a href="https://policies.google.com/terms" style={{ color: '#4EAAFF' }}>Terms of Service</a> apply.
            </BodyGL>
          </AlignCenter>
        </Container>
      </FooterWrapper>
    </>
  )
}
