import React, { useContext } from 'react'
import { SpacerTopBottomLarge, SpacerTopBottomXL, SpacerTopBottomXXXL } from '../../../design/spacer/spacer'
import { DividerHorizontal } from '../../../global/components/divider'
import { AlignCenter, BodySPDM, Container, Sandwich, TitleGHM } from '../../../global/style/global.styles'
import { WhiteContainer } from '../style/home.styles'
import { VideoSlider } from './videoSlider'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { ModalContext } from '../../../context/interface/modal.provider'
import ReactPlayer from 'react-player'

export const VideoSection = (props) => {

    const {
        homeVideoModal,
        tempUrl,
        playing,
        closeVideo
    } = useContext(ModalContext);

    return (
        <>
            <div className={homeVideoModal ? "modal open" : "modal"}>
                <ReactPlayer
                    url={tempUrl}
                    playing={playing}
                    controls={true}
                    width={"100%"}
                    height={"100%"}
                />
                <CloseRoundedIcon onClick={closeVideo} />
            </div>
            <WhiteContainer ref={props.refProp}>
                <Container>
                    <Sandwich>
                        <AlignCenter>
                            <TitleGHM style={{ textTransform: 'uppercase' }}>
                                {props.content && props.content.title}
                            </TitleGHM>
                            <SpacerTopBottomXL />
                            <BodySPDM style={{ color: '#D68808' }}>
                                {props.content && props.content.subTitle}
                            </BodySPDM>
                            <SpacerTopBottomXXXL />
                            <DividerHorizontal
                                borderBottom={"2px dashed #BABABA"}
                                width={160}

                            />
                        </AlignCenter>
                    </Sandwich>
                    <VideoSlider
                        content={props.content && props.content.slider}
                        location={props.location}
                    />
                </Container>
            </WhiteContainer>
        </>
    )
}
