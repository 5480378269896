import React, { useContext, useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom';
import { ContentContext } from '../../../context/content/content.provider';
import { CtaBtnPersist } from '../../../global/components/ctaBtnPersist';
import { Footer } from '../../../global/components/footer';
import useWindowDimensions from '../../../global/components/screenSize';
import ScrollToTop from '../../../global/components/scrollToTop';
import { Container, FlexBoxSA, Sandwich } from '../../../global/style/global.styles';
import { AlbumGallery } from '../components/albumGallery';
import { AlbumHeroSection } from '../components/albumHeroSection';
import { AlbumNav } from '../components/albumNav';

export const Album = (props) => {

  const { width, height } = useWindowDimensions();

  const { albumId } = useParams();

  const albumNavRef = useRef(null);

  const { eventDisplay, setEventDisplay } = useContext(ContentContext);

  const executeScroll = () => {
    albumNavRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
  }

  return (
    <>
      <ScrollToTop />
      <AlbumHeroSection
        heroImg={props.content.gallery && props.content.gallery.albums?.filter((x) => x.id === albumId)[0]?.cover}
        navColor={props.content.gallery && props.content.gallery.albums?.filter((x) => x.id === albumId)[0]?.navColor}
        scroll={executeScroll}
        height={height}
        width={width}
      />
      <AlbumNav
        albumNavRef={albumNavRef}
        navData={props.content.gallery && props.content.gallery.albums?.filter((x) => x.id === albumId)[0]?.events}
        eventDisplay={eventDisplay}
        setEventDisplay={setEventDisplay}
        width={width}
      />
      <AlbumGallery
        data={props.content.gallery && props.content.gallery.albums?.filter((x) => x.id === albumId)[0]?.images}
        eventDisplay={""}
      // eventDisplay={eventDisplay}
      />
      <Footer />
      {
        props.scroll > 120
        &&
        <CtaBtnPersist
          clicked={() => props.navigate("/contact")}
        />
      }
    </>
  )
}
