import React, { createContext, useState } from 'react'

export const ContentContext = createContext()

export const ContentProvider = ({ children }) => {

    const [eventDisplay, setEventDisplay] = useState("Haldi")

    return (
        <ContentContext.Provider
            value={{
                eventDisplay,
                setEventDisplay
            }}
        >
            {children}
        </ContentContext.Provider>
    )
}

