import React from 'react'
import styled from 'styled-components'
import CalendarMonthRoundedIcon from '@mui/icons-material/CalendarMonthRounded';
import useWindowDimensions from './screenSize';

const CtaBtnPersistWrapper = styled.div`
    position: fixed;
    bottom: 40px;
    right: 40px;

    @media only screen and (max-width: 850px) {
        bottom: 40px;
        right: 20px;
    }
`;

const CtaBtnPersistContainer = styled.div`
    background-color: #D68808;
    border-radius: 50%;
    width: 72px;
    height: 72px;
    position: relative;
    cursor: pointer;
    box-shadow: 0px 1px 4px 0px rgba(183,183,183,0.75);
-webkit-box-shadow: 0px 1px 4px 0px rgba(183,183,183,0.75);
-moz-box-shadow: 0px 1px 4px 0px rgba(183,183,183,0.75);
    &:hover {
        transform: scale(1.06);
        transition: all 0.3s ease;
        background-color: #000000;
        box-shadow: 0px 2px 4px 1px rgba(150,150,150,0.75);
-webkit-box-shadow: 0px 2px 4px 1px rgba(150,150,150,0.75);
-moz-box-shadow: 0px 2px 4px 1px rgba(150,150,150,0.75);
    }
    &:not(:hover) {
        transform: scale(1);
        transition: all 0.3s ease;
        box-shadow: 0px 1px 4px 0px rgba(183,183,183,0.75);
-webkit-box-shadow: 0px 1px 4px 0px rgba(183,183,183,0.75);
-moz-box-shadow: 0px 1px 4px 0px rgba(183,183,183,0.75);
    }

    @media only screen and (max-width: 850px) {
        width: 48px;
        height: 48px;
    }
`;

export const CtaPersistText = styled.div`
    font-family: 'Nunito', sans-serif;
    font-size: ${(props) => props.theme.fontSizes.body};
    color: ${(props) => props.theme.colors.text.white};
    font-weight: ${(props) => props.theme.fontWeights.bold};
    text-align: center;
    line-height: 18px;
`;

export const AbsoluteCenter = styled.div`
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
`;

export const CtaBtnPersist = (props) => {

    const { width, height } = useWindowDimensions();

    return (
        <>
            <CtaBtnPersistWrapper>
                <CtaBtnPersistContainer onClick={props.clicked}>
                    <AbsoluteCenter>
                        {
                            width > 850
                                ?
                                <CtaPersistText>
                                    Book now
                                </CtaPersistText>
                                :
                                <CalendarMonthRoundedIcon
                                    sx={{
                                        color: 'white'
                                    }}
                                />
                        }
                    </AbsoluteCenter>
                </CtaBtnPersistContainer>
            </CtaBtnPersistWrapper>
        </>
    )
}
