import { Grid } from '@mui/material'
import React from 'react'
import { Footer } from '../../global/components/footer'
import { Navbar } from '../../global/components/navbar'
import useWindowDimensions from '../../global/components/screenSize'
import ScrollToTop from '../../global/components/scrollToTop'
import { ContactForm } from './components/contactForm'
import { ContactDetails, ContactUs } from './components/contactDetails'
import { Container, Sandwich, Squeezer } from '../../global/style/global.styles'

export const Contact = (props) => {

  return (
    <>
      <ScrollToTop />
      <Navbar
        color="black"
      />
      {
        props.width > 850
          ?
          <Sandwich>
            <Grid container alignItems="normal">
              <Grid item xl={7} lg={7} md={6} sm={12} xs={12}>
                <ContactDetails
                  width={props.width}
                  contact={props.content.contact}
                />
              </Grid>
              <Grid item xl={5} lg={5} md={6} sm={12} xs={12}>
                <ContactForm
                  width={props.width}
                />
              </Grid>
            </Grid>
          </Sandwich>
          :
          <Grid container alignItems="normal">
            <Grid item xl={7} lg={7} md={6} sm={12} xs={12}>
              <ContactDetails
                width={props.width}
                contact={props.content.contact}
              />
            </Grid>
            <Grid item xl={5} lg={5} md={6} sm={12} xs={12}>
              <ContactForm
                width={props.width}
              />
            </Grid>
          </Grid>
      }
      <Footer />
    </>
  )
}
