import { Route, Routes, useNavigate } from "react-router-dom";

import { Home } from "./pages/home/home";
import { About } from "./pages/about/about";
import { Contact } from "./pages/contact/contact";
import { Gallery } from "./pages/gallery/gallery";
import { Albums } from "./pages/gallery/screens/albums";
import { Wedding } from "./pages/gallery/screens/wedding";
import { Album } from "./pages/gallery/screens/album";
import { Films } from "./pages/gallery/screens/films";
import { Reception } from "./pages/gallery/screens/reception";

import Cookies from "./pages/others/cookies";
import NotFound from "./pages/others/notFound";
import Attribution from "./pages/others/attribution";
import PrivacyPolicy from "./pages/others/privacyPolicy";
import Terms from "./pages/others/terms";
import { GalleryOutlet } from "./pages/gallery/components/galleryOutlet";
import useWindowDimensions from "./global/components/screenSize";
import { ApiContext } from "./context/api/api.provider";
import { useContext, useEffect, useState } from "react";
import { Practise } from "./global/components/practise";

function App() {



  const { width, height } = useWindowDimensions();

  const { content, loadingContent } = useContext(ApiContext);

  const navigate = useNavigate()

  const [scroll, setScroll] = useState(false)

  const handleScroll = () => {

    setScroll(window.scrollY)
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
  }, []);

  return (
    <>
      <Routes>
        <Route path="/" element={<Home
          width={width}
          height={height}
          navigate={navigate}
          content={content}
          scroll={scroll}
        />} />
        <Route path="/about" element={<About
          content={content}
          navigate={navigate}
        />} />
        <Route path="/contact" element={<Contact
          width={width}
          navigate={navigate}
          content={content}
        />} />
        <Route path="/gallery" element={
          <Gallery
            navigate={navigate}
            content={content}
          />}>
          {/* {
            GALLERYNAVDATA.map((item, index) => (
              <Route key={index} path={item.pathname} element={<GalleryOutlet
                title={item.title}
                pathname={item.pathname}
              />} />
            ))
          }
          <Route path="*" element={<NotFound />} /> */}
          <Route path="albums" element={
            <Albums
              navigate={navigate}
              width={width}
              content={content}
              loadingContent={loadingContent}
            />
          } />
        </Route>
        <Route path="/gallery/:albumId" element={<Album
          navigate={navigate}
          content={content}
          scroll={scroll}
        />} />
        <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
        <Route path="/cookies" element={<Cookies />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/attribution" element={<Attribution />} />
        <Route path="*" element={<NotFound />} />
        {/* <Route path="/practise" element={<Practise />} /> */}
      </Routes>
    </>
  );
}

export default App;
