import React from 'react';
import ReactDOM from 'react-dom/client';

import App from './App';

import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { ApiProvider } from './context/api/api.provider';
import { ArchiveProvider } from './context/archive/archive.provider';
import { ModalProvider } from './context/interface/modal.provider';
import { theme } from './design/theme';
import { ConfigProvider } from './context/config/config.provider';
import { ContentProvider } from './context/content/content.provider';
import { UpdateProvider } from './context/update/update.provider';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <GoogleReCaptchaProvider reCaptchaKey="6LfTLFckAAAAANICbrz0B57vgBFDzUlxbxI3_5tl">
    <ConfigProvider>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <UpdateProvider>
            <ApiProvider>
              <ModalProvider>
                <ArchiveProvider>
                  <ContentProvider>
                    <App />
                  </ContentProvider>
                </ArchiveProvider>
              </ModalProvider>
            </ApiProvider>
          </UpdateProvider>
        </BrowserRouter>
      </ThemeProvider>
    </ConfigProvider>
  </GoogleReCaptchaProvider>
);

