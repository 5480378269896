import { Grid } from '@mui/material'
import React from 'react'
import { SpacerTopBottomLarge, SpacerTopBottomSmall } from '../../design/spacer/spacer'
import { BodyGH, Container, SubTitleGHB } from '../../global/style/global.styles'
import { Helmet } from "react-helmet";

const Attribution = () => {
  return (
    <Container>
      <Helmet>
        <title>The Oyster Studios | Attribution</title>
      </Helmet>
      <h1>Attribution</h1>
      <SpacerTopBottomLarge />
      <Grid container>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Grid container sx={{ marginBottom: '16px' }}>
            <Grid item xl={4} lg={4} md={4} sm={12} xs={12} sx={{ padding: '8px 0', borderBottom: '2px solid black' }}>
              <SubTitleGHB>
                Item
              </SubTitleGHB>
            </Grid>
            <Grid item xl={8} lg={8} md={8} sm={12} xs={12} sx={{ padding: '8px 0', borderBottom: '2px solid black' }}>
              <SubTitleGHB>
                Source / Author
              </SubTitleGHB>
            </Grid>
          </Grid>
          <Grid container sx={{ marginBottom: '8px' }}>
            <Grid item xl={4} lg={4} md={4} sm={12} xs={12} sx={{ padding: '8px 0', borderBottom: '1px solid grey' }}>
              <BodyGH>
                Facebook icon
              </BodyGH>
            </Grid>
            <Grid item xl={8} lg={8} md={8} sm={12} xs={12} sx={{ padding: '8px 0', borderBottom: '1px solid grey' }}>
              <BodyGH>
                <a href="https://www.flaticon.com/free-icons/facebook" title="facebook icons">Facebook icons created by Freepik - Flaticon</a>
              </BodyGH>
            </Grid>
          </Grid>
          <Grid container sx={{ marginBottom: '8px' }}>
            <Grid item xl={4} lg={4} md={4} sm={12} xs={12} sx={{ padding: '8px 0', borderBottom: '1px solid grey' }}>
              <BodyGH>
                Instagram icon
              </BodyGH>
            </Grid>
            <Grid item xl={8} lg={8} md={8} sm={12} xs={12} sx={{ padding: '8px 0', borderBottom: '1px solid grey' }}>
              <BodyGH>
                <a href="https://www.flaticon.com/free-icons/instagram" title="instagram icons">Instagram icons created by Pixel perfect - Flaticon</a>
              </BodyGH>
            </Grid>
          </Grid>
          <Grid container sx={{ marginBottom: '8px' }}>
            <Grid item xl={4} lg={4} md={4} sm={12} xs={12} sx={{ padding: '8px 0', borderBottom: '1px solid grey' }}>
              <BodyGH>
                Mail icon
              </BodyGH>
            </Grid>
            <Grid item xl={8} lg={8} md={8} sm={12} xs={12} sx={{ padding: '8px 0', borderBottom: '1px solid grey' }}>
              <BodyGH>
                <a href="https://www.flaticon.com/free-icons/email" title="email icons">Email icons created by Uniconlabs - Flaticon</a>
              </BodyGH>
            </Grid>
          </Grid>
          <Grid container sx={{ marginBottom: '8px' }}>
            <Grid item xl={4} lg={4} md={4} sm={12} xs={12} sx={{ padding: '8px 0', borderBottom: '1px solid grey' }}>
              <BodyGH>
                About dots animation
              </BodyGH>
            </Grid>
            <Grid item xl={8} lg={8} md={8} sm={12} xs={12} sx={{ padding: '8px 0', borderBottom: '1px solid grey' }}>
              <BodyGH>
                https://lottiefiles.com/jfdunphy
              </BodyGH>
            </Grid>
          </Grid>
          <Grid container sx={{ marginBottom: '8px' }}>
            <Grid item xl={4} lg={4} md={4} sm={12} xs={12} sx={{ padding: '8px 0', borderBottom: '1px solid grey' }}>
              <BodyGH>
                404 page animation
              </BodyGH>
            </Grid>
            <Grid item xl={8} lg={8} md={8} sm={12} xs={12} sx={{ padding: '8px 0', borderBottom: '1px solid grey' }}>
              <BodyGH>
              https://lottiefiles.com/kvk2rbpe8tr6ecjt
              </BodyGH>
            </Grid>
          </Grid>
          <Grid container sx={{ marginBottom: '8px' }}>
            <Grid item xl={4} lg={4} md={4} sm={12} xs={12} sx={{ padding: '8px 0', borderBottom: '1px solid grey' }}>
              <BodyGH>
                404 page animation
              </BodyGH>
            </Grid>
            <Grid item xl={8} lg={8} md={8} sm={12} xs={12} sx={{ padding: '8px 0', borderBottom: '1px solid grey' }}>
              <BodyGH>
              <a href="https://www.freepik.com/free-vector/set-floral-decorative-elements-gold-color_6080607.htm#page=2&query=decorative%20elements&position=16&from_view=search&track=sph">Image by starline</a> on Freepik
              </BodyGH>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <SpacerTopBottomLarge />
      <h2>For any request or grievance, contact below</h2>
      <SpacerTopBottomSmall />
      <ul>
        <li>
          <p>By email: oysterstudiosglb@gmail.com</p>
        </li>
        <li>
          <p>By visiting this page on our website: <a href="https://theoysterstudios.com/contact" rel="external nofollow noopener" target="_blank">https://theoysterstudios.com/contact</a></p>
        </li>
        <li>
          <p>By phone number: +91 9741428892</p>
        </li>
        <li>
          <p>By mail: Valmark Orchard Square, J.P. Nagar 8th Phase, Kothnur Village main road, Bengaluru, Karnataka - 560076</p>
        </li>
      </ul>
    </Container>
  )
}

export default Attribution