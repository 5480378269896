import React, { createContext, useState } from 'react'
import { useRef } from 'react';

export const ModalContext = createContext()

export const ModalProvider = ({ children }) => {

    const [tempUrl, setTempUrl] = useState("");
    const [playing, setPlaying] = useState(true);
    const [imgModal, setImgModal] = useState(false);
    const [filmsVideoModal, setFilmsVideoModal] = useState(false);
    const [homeVideoModal, setHomeVideoModal] = useState(false);

    const openFilmsVideo = (url) => {
        setTempUrl(url);
        setFilmsVideoModal(true);
        setPlaying(true);
    }

    const openHomeVideo = (url) => {
        setTempUrl(url);
        setHomeVideoModal(true);
        setPlaying(true);
    }

    const closeVideo = () => {
        setFilmsVideoModal(false);
        setHomeVideoModal(false);
        setPlaying(false);
    }

    return (
        <ModalContext.Provider
            value={{
                playing,
                setPlaying,
                tempUrl,
                setTempUrl,
                closeVideo,
                homeVideoModal,
                setHomeVideoModal,
                imgModal,
                setImgModal,
                filmsVideoModal,
                setFilmsVideoModal,
                openHomeVideo,
                openFilmsVideo
                
            }}
        >
            {children}
        </ModalContext.Provider>
    )
}