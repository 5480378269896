import React, { createContext, useState } from 'react'

export const ConfigContext = createContext()

export const ConfigProvider = ({ children }) => {


    return (
        <ConfigContext.Provider
            value={{
                
            }}
        >
            {children}
        </ConfigContext.Provider>
    )
}

