import styled from 'styled-components'

// ----------------------------------------CONTAINERS----------------------------------------//

export const Template = styled.div`
    
    
    @media only screen and (min-width: 1800px) {
    }
    @media only screen and (max-width: 1300px) {  
    }
    @media only screen and (max-width: 1100px) {
    }
    @media only screen and (max-width: 850px) {   
    }
    @media only screen and (max-width: 650px) {
    }
    @media only screen and (max-width: 450px) {
    }
    @media only screen and (max-width: 370px) {
    }
`;

export const HeroSectionWrapper = styled.div`
    height: 100vh;
    position: relative;
`;

export const HeroVideo = styled.video`
    position: fixed;
    object-fit: cover;
    z-index: -1;
    width: 100%;
    height: 100%;
    background-color: black;
`;

export const WhiteContainer = styled.div`
    background-color: ${(props) => props.theme.colors.ui.white};
`;

export const VideoBlockWrapper = styled.div`
    padding: 0 4px;
`;

export const VideoBlockContainer = styled.div`
    border-radius: 8px;
    position: relative;
    height: 280px;
    cursor: pointer;

    &:hover {
        transform: scale(1.01);
        transition: all 0.4s ease;
        box-shadow: 0px 2px 6px 2px rgba(189,189,189,0.75);
-webkit-box-shadow: 0px 2px 6px 2px rgba(189,189,189,0.75);
-moz-box-shadow: 0px 2px 6px 2px rgba(189,189,189,0.75);
    }
    &:not(:hover) {
        transform: scale(1);
        transition: all 0.4s ease;
    }

    @media only screen and (min-width: 1800px) {
        height: 320px;
    }
    @media only screen and (max-width: 1400px) {
        height: 240px;
    }
    @media only screen and (max-width: 1100px) {
        
    }
    @media only screen and (max-width: 850px) {
        
    }
    @media only screen and (max-width: 800px) {
        height: 200px;
    }
    @media only screen and (max-width: 450px) {
        height: 260px;
    }
    @media only screen and (max-width: 370px) {
        height: 220px;
    }
`;

export const Thumbnail = styled.img`
    width: 100%;
    height: 100%;
    border-radius: 8px;
`;

export const InfoPart = styled.div`
    padding-top: ${(props) => props.theme.space[2]};
    padding-bottom: ${(props) => props.theme.space[2]};
    padding-left: ${(props) => props.theme.space[3]};
    padding-right: ${(props) => props.theme.space[3]};
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
    bottom: 0;
    width: 100%;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
`;

export const GalleryContainer = styled.div`
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
    -webkit-column-width: 33%;
    -moz-column-width: 33%;
    column-width: 33%;

    @media only screen and (max-width: 850px) {   
        -webkit-column-count: 2;
        -moz-column-count: 2;
        column-count: 2;
    }
    @media only screen and (max-width: 650px) {
        -webkit-column-count: 1;
        -moz-column-count: 1;
        column-count: 1;
        -webkit-column-width: 100%;
        -moz-column-width: 100%;
        column-width: 100%;
    }
`;

export const ImagesContainer = styled.div`
    -webkit-transition: all 350ms ease;
    transition: all 350ms ease;
    cursor: pointer;
    margin-bottom: 12px;

    &:hover {
        filter: opacity(0.8);
    }
`;

export const Image = styled.img`
    width: 100%;
`;

export const TestimonialBlockWrapper = styled.div`
    padding-top: 68px;
    position: relative;

    @media only screen and (max-width: 850px) {  
        margin-bottom: 64px;
    }
    @media only screen and (max-width: 450px) {  
        margin-bottom: 56px;
    }
    @media only screen and (max-width: 370px) {  
        margin-bottom: 48px;
    }
`;

export const TestimonialBlockContainer = styled.div`
    padding-top: ${(props) => props.theme.space[10]};
    padding-bottom: ${(props) => props.theme.space[3]};
    padding-left: ${(props) => props.theme.space[3]};
    padding-right: ${(props) => props.theme.space[3]};
    background-color: ${(props) => props.theme.colors.ui.white};
    border: 2px solid #FFD9A3;
    border-radius: 24px;
    width: 360px;
    height: 100%;
    box-shadow: 0px 6px 8px 4px rgba(255,217,163,0.15);
-webkit-box-shadow: 0px 6px 8px 4px rgba(255,217,163,0.15);
-moz-box-shadow: 0px 6px 8px 4px rgba(255,217,163,0.15);

    @media only screen and (max-width: 1400px) {  
        width: 320px;
    }
    @media only screen and (max-width: 1100px) {  
        width: 280px;
    }
    @media only screen and (max-width: 850px) {  
        width: 400px;
    }
    @media only screen and (max-width: 450px) {  
        width: 360px;
    }
    @media only screen and (max-width: 350px) {  
        width: 320px;
    }
`;

export const TestimonialDpHolder = styled.div`
    width: 136px;
    height: 136px;
    border-radius: 50%
`;

export const TestimonialDp = styled.img`
    width: 100%;
    height: 100%;
    border-radius: 50%
`;

export const CtaLogoHolder = styled.div`
    width: 80px;
    height: 80px;
`;

export const CtaLogo = styled.img`
    width: 100%;
    height: 100%;
`;

export const CtaText = styled.div`
    font-size: ${(props) => props.theme.fontSizes.subTitle};
    color: ${(props) => props.theme.colors.text.greyHigh};
    font-weight: ${(props) => props.theme.fontWeights.regular};
    line-height: 32px;
    letter-spacing: 0.2px;

    @media only screen and (max-width: 370px) {  
        font-size: ${(props) => props.theme.fontSizes.body};
        line-height: 24px;
    }
`;

export const CtaTitle = styled.div`
    font-family: 'Zilla Slab', serif;
    font-size: ${(props) => props.theme.fontSizes.subHeading};
    color: ${(props) => props.theme.colors.text.greyMid};
    font-weight: ${(props) => props.theme.fontWeights.semiBold};

    @media only screen and (max-width: 850px) {  
        
    }
    @media only screen and (max-width: 450px) {  
        font-size: ${(props) => props.theme.fontSizes.subTitle};
    }
    @media only screen and (max-width: 350px) {  
        font-size: ${(props) => props.theme.fontSizes.body};
    }
`;

export const AlbumDetailContainer = styled.div`
    padding-top: ${(props) => props.theme.space[2]};
    padding-bottom: ${(props) => props.theme.space[2]};
    padding-left: ${(props) => props.theme.space[3]};
    padding-right: ${(props) => props.theme.space[3]};
    border-radius: 24px;
    background-color: rgba(255,255,255,0.8);
`;






