import React, { useState } from 'react'
import { GalleryContainer, Image, ImagesContainer } from '../style/home.styles'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { Sandwich } from '../../../global/style/global.styles';

export const ImagesCollage = (props) => {

    const [tempUrl, setTempUrl] = useState("");
    const [imgModal, setImgModal] = useState(false);

    const showImage = (url) => {
        setTempUrl(url);
        setImgModal(true);
    }

    return (
        <>
            <div className={imgModal ? "modal open" : "modal"}>
                <img src={tempUrl} />
                <CloseRoundedIcon onClick={() => setImgModal(false)} />
            </div>
            <Sandwich>
                <GalleryContainer>
                    {
                        props.content
                            &&
                            props.width > 650
                            ?
                            props.content.map((item, index) => (
                                <ImagesContainer key={index} onClick={() => showImage(item)}>
                                    <Image src={item} />
                                </ImagesContainer>
                            ))
                            :
                            props.content?.slice(0, 4).map((item, index) => (
                                <ImagesContainer key={index} onClick={() => showImage(item)}>
                                    <Image src={item} />
                                </ImagesContainer>
                            ))
                    }
                </GalleryContainer>
            </Sandwich>
        </>
    )
}
