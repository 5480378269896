import { collection, doc, getDoc } from 'firebase/firestore';
import React, { createContext, useState, useEffect, useContext, useCallback } from 'react'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { db } from '../../firebase/firebase';
import { UpdateContext } from '../update/update.provider';

export const ApiContext = createContext()

export const ApiProvider = ({ children }) => {

    const { executeRecaptcha } = useGoogleReCaptcha();

    // Create an event handler so you can call the verification on button click event or form submit
    const handleReCaptchaVerify = useCallback(async () => {
        if (!executeRecaptcha) {
            console.log('Execute recaptcha not yet available');
            return;
        }

        const token = await executeRecaptcha('yourAction');
        // Do whatever you want with the token
        // console.log(token)
    }, [executeRecaptcha]);

    // You can use useEffect to trigger the verification as soon as the component being loaded
    useEffect(() => {
        handleReCaptchaVerify();
    }, [handleReCaptchaVerify]);

    const { refresh, profileRefresh } = useContext(UpdateContext);

    const [content, setContent] = useState([]);

    const [loadingContent, setLoadingContent] = useState(true)

    const contentDocRef = doc(db, "content", "version1.0");
    const leadsCollectionRef = collection(db, "leads");

    useEffect(() => {

        try {

            const getContent = async () => {
                const data = await getDoc(contentDocRef)
                    .then((doc) => {
                        setContent(doc.data())
                    })
            };

            getContent();

            setLoadingContent(false);

        } catch (error) {
            const { code, message } = error;
            console.log(error);
        }

    }, [])

    useEffect(() => {

        try {

            const getContent = async () => {
                const data = await getDoc(contentDocRef)
                    .then((doc) => {
                        setContent(doc.data())
                    })
            };

            getContent();

            setLoadingContent(false);

        } catch (error) {
            const { code, message } = error;
            console.log(error);
        }

    }, [refresh])

    return (
        <ApiContext.Provider
            value={{
                loadingContent,
                setLoadingContent,
                content,
                setContent,
                contentDocRef,
                leadsCollectionRef
            }}
        >
            {children}
        </ApiContext.Provider>
    )
}