import React from 'react'
import { SpacerBottomSmall, SpacerTopBottomLarge, SpacerTopBottomXL } from '../../../design/spacer/spacer'
import { AbsoluteCenter, AbsoluteTopCenter, AlignCenter, BodyGMI, SubTitleGMI, SubTitleMZilla } from '../../../global/style/global.styles'
import { TestimonialBlockContainer, TestimonialBlockWrapper, TestimonialDp, TestimonialDpHolder } from '../style/home.styles'

export const TestimonialBlock = (props) => {
    return (
        <>
            <TestimonialBlockWrapper>
                <AbsoluteTopCenter>
                    <TestimonialDpHolder>
                        <TestimonialDp src={props.dp} />
                    </TestimonialDpHolder>
                </AbsoluteTopCenter>
                <TestimonialBlockContainer>
                    <AlignCenter>
                        <SubTitleMZilla>
                            {props.name}
                        </SubTitleMZilla>
                    </AlignCenter>
                    <SpacerTopBottomLarge />
                    <BodyGMI>
                        {props.line1}
                    </BodyGMI>
                    <SpacerBottomSmall />
                    <BodyGMI>
                        {props.line2}
                    </BodyGMI>
                    <SpacerBottomSmall />
                    <BodyGMI>
                        {props.line3}
                    </BodyGMI>
                    <SpacerBottomSmall />
                    <BodyGMI>
                        {props.line4}
                    </BodyGMI>
                    <SpacerBottomSmall />
                    <BodyGMI>
                        {props.line5}
                    </BodyGMI>
                    <SpacerBottomSmall />
                </TestimonialBlockContainer>
            </TestimonialBlockWrapper>
        </>
    )
}
